import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getUser } from "../../../../api/requests";
import { ToggleColorMode } from "../../../content/toggle-color";
import { LogoSmartCityHdhAa } from "../../../icons";
import { StatusBar } from "./status-bar";
import { UserMenu } from "./user-menu";

export const Header = () => {
  const { isLoading, data } = useQuery(["user"], () => getUser());

  return (
    <header className="pl-3 md:pl-7 w-full h-14 dark:bg-bg-secondary-dark bg-bg-secondary-light shrink-0">
      <div className="flex h-full items-center flex-row justify-between">
        <Link to="/">
          <div className="flex flex-row justify-center items-center">
            <LogoSmartCityHdhAa className="icon-no-colormode" />
            <span className="ml-2 md:ml-5 text-[22px] font-bold">
              Heidenheim
            </span>
          </div>
        </Link>

        <div className="flex flex-row"></div>
        <div className="flex items-center flex-row h-full">
          <StatusBar />

          <div className="ml-6">
            <ToggleColorMode />
          </div>
          <div className="ml-0 h-full">
            <UserMenu user={data} />
          </div>
        </div>
      </div>
    </header>
  );
};

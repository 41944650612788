import { CSSProperties, memo, SVGProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StateboardSorting } from "../../../api/types";
import { SVG_SIZE, SVG_THICK, SVG_THICKER } from "../../../constants/svg-size";
import { Size } from "../../../types/util";
import { ScDbTrend } from "../../icons";
import { BlurredIcon } from "../blurred-icon";

export type TrendDirection = "up" | "down" | "equal";
export type TrendComparedTo = "DAY" | "WEEK" | "MONTH" | "YEAR";

export interface TrendProps {
  direction: TrendDirection;
  value: string;
  comparedTo: TrendComparedTo | StateboardSorting;
}
export interface TrendIconProps extends React.SVGProps<SVGSVGElement> {
  direction: TrendDirection;
  blured?: boolean;
  thick?: boolean;
  thicker?: boolean;
  affectedByColorMode?: boolean;
  size?: Size | "xs" | "xxl";
}
export const TrendIcon = ({
  direction,
  thick,
  thicker,
  size = "xl",
  blured,
  affectedByColorMode = false,
  ...rest
}: TrendIconProps) => {
  const iconStyle = useMemo<CSSProperties | undefined>(() => {
    switch (direction) {
      case "up":
        return undefined;
      case "down":
        return {
          transform: "scale(1, -1) translateY(0.25rem)",
        };
      default:
        return {
          transform: "rotateZ(45deg)",
        };
    }
  }, [direction]);
  const iconProps = useMemo<Omit<SVGProps<SVGElement>, "ref">>(() => {
    let props: Omit<SVGProps<SVGElement>, "ref"> = {
      style: iconStyle,
      ...SVG_SIZE[size],
    };

    if (thick || thicker) {
      props = {
        ...props,
        className: `${props.className} ${
          thicker ? SVG_THICKER.className : SVG_THICK.className
        } ${!affectedByColorMode ? "icon-no-colormode" : ""} `,
      };
    }

    return {
      ...props,
      className: `${props.className} ${rest.className}`,
    };
  }, [iconStyle, size, thick, thicker, rest.className, affectedByColorMode]);

  const icon = <ScDbTrend {...iconProps} />;
  if (direction === "equal") return <span>-</span>;
  if (blured) return <BlurredIcon Icon={icon} />;
  return icon;
};

export const Trend = memo(({ direction, value, comparedTo }: TrendProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-end">
      <div className="flex flex-row items-center relative">
        <div className="relative top-0.5">
          <TrendIcon
            direction={direction}
            blured
            thick
            affectedByColorMode
          ></TrendIcon>
        </div>
        <div className="ml-2">
          <span className="text-lg font-bold">{value}</span>
        </div>
      </div>
      <div className="relative -top-1.5">
        <span className="text-light text-xs">
          {t(`COMPARED_TO_${comparedTo.toUpperCase()}`)}
        </span>
      </div>
    </div>
  );
});

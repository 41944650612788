import { useCallback } from "react";

const bgOn = "dark:bg-active-dark bg-active-light pointer-events-none ";
const bgOff = "dark:bg-inactive-dark bg-inactive-light";

const colorOff = "dark:text-focus-dark text-focus-light";
const colorOn = "dark:text-info-dark text-info-light";

const hoverOff = "hover:dark:text-info-dark hover:text-info-light";

export interface ToggleButtonProps<V> {
  label: string;
  value: V;
  on?: boolean;
  onClick?: (value: V) => void;
}

export const ToggleButton = <V,>({
  label,
  value,
  on,
  onClick,
}: ToggleButtonProps<V>) => {
  const handleClick = useCallback(() => {
    onClick && onClick(value);
  }, [onClick, value]);

  const bg = on ? bgOn : bgOff;
  const color = on ? colorOn : colorOff;
  const hover = on ? "" : hoverOff;
  return (
    <button
      onClick={handleClick}
      className={` rounded-md text-sm dark:shadow-xs-dark shadow-xs-light px-3 py-0.5 text-center ${bg} ${hover} ${color} mr-1.5 last:mr-0 transition-colors duration-200`}
    >
      {label}
    </button>
  );
};

import React, { useCallback, useEffect, useRef, useState } from "react";

const COLOR_MODE_LOCAL_STORAGE_KEY = "color-mode";
const TRANSITION_CLASS = "color-transition";

export type ColorMode = "light" | "dark";

export interface ColorModeContextValue {
  colorMode: ColorMode;
  setColorMode: (colorMode: ColorMode) => void;
}

const isColorModeValid = (colorMode: string): colorMode is ColorMode => {
  return ["light", "dark"].includes(colorMode);
};

const initalColorMode = (): ColorMode => {
  const localStorageColorMode = localStorage.getItem(
    COLOR_MODE_LOCAL_STORAGE_KEY
  );
  if (localStorageColorMode && isColorModeValid(localStorageColorMode)) {
    return localStorageColorMode;
  }
  // Default Dark Mode
  return "dark";
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
};

const INITAL_CONTEXT_VALUE: ColorModeContextValue = {
  colorMode: initalColorMode(),
  setColorMode: () => {},
};

export const ColorModeContext = React.createContext(INITAL_CONTEXT_VALUE);

export interface ColorModeProviderProps {
  children: React.ReactNode;
}

export const ColorModeProvider = ({ children }: ColorModeProviderProps) => {
  const initalRender = useRef(true);

  const [colorMode, setColorModeState] = useState<ColorMode>(
    INITAL_CONTEXT_VALUE.colorMode
  );

  const setColorMode = useCallback((value: ColorMode) => {
    setColorModeState(value);
    localStorage.setItem(COLOR_MODE_LOCAL_STORAGE_KEY, value);
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    if (!html) return;

    if (initalRender.current) {
      initalRender.current = false;
      html.classList.toggle("dark", colorMode === "dark");
      html.classList.toggle("light", colorMode !== "dark");
    }

    html.classList.add(TRANSITION_CLASS);

    const togTimout = setTimeout(() => {
      html.classList.toggle("dark", colorMode === "dark");
      html.classList.toggle("light", colorMode !== "dark");
    }, 100);

    const transTimeout = setTimeout(() => {
      html.classList.remove("color-transition");
    }, 200);

    return () => {
      clearTimeout(transTimeout);
      clearTimeout(togTimout);
      html.classList.remove("color-transition");
    };
  }, [colorMode]);

  const context = {
    colorMode,
    setColorMode,
  };

  return (
    <ColorModeContext.Provider value={context}>
      {children}
    </ColorModeContext.Provider>
  );
};

import { useContext, useMemo } from "react";
import { STATE_BOARDS } from "../../../constants/stateboards";
import { DashboardContext } from "../../../context/dashboard";
import { StateboardType } from "../../../types/stateboard";
import { Grid } from "../../grid";
import { GridItem } from "../../grid/item";
import { FillCircle } from "../../util/fill-circle";
import { Layout } from "../layout";
const Main = () => {
  const context = useContext(DashboardContext);
  const gridItems = useMemo(() => {
    return Object.entries(context.stateboards).map(([id, stateboard]) => {
      if (!((stateboard.tyoe as StateboardType) in STATE_BOARDS)) {
        return null;
      }

      const Stateboard =
        STATE_BOARDS[stateboard.tyoe as StateboardType].Component;
      const layout =
        STATE_BOARDS[stateboard.tyoe as StateboardType].layoutDefinition;

      return (
        <GridItem
          key={id}
          data-grid={{ i: id, ...stateboard.position, ...layout }}
          item={<Stateboard id={id} />}
        ></GridItem>
      );
    });
  }, [context.stateboards]);
  return useMemo(
    () => (
      <Layout>
        <Grid>{gridItems}</Grid>
      </Layout>
    ),
    [gridItems]
  );
};

export { Main as Index };

import { SVGProps } from "react";
import { Size } from "../types/util";
//TODO: refactor
export const SVG_SIZE: Record<
  Size | "xs" | "xxl",
  { width: number; height: number }
> = {
  xs: { width: 9, height: 9 },
  sm: { width: 12, height: 12 },
  md: { width: 18, height: 18 },
  lg: { width: 21, height: 21 },
  xl: { width: 24, height: 24 },
  xxl: { width: 58, height: 58 },
};

export const SVG_THICK = {
  className: "svg-thick",
};
export const SVG_THICKER = {
  className: "svg-thicker",
};

export type SVGThickness = "normal" | "thick" | "thicker";

interface SVGConfig {
  size?: Size | "xs" | "xxl";
  thickness?: SVGThickness;
  props?: SVGProps<SVGElement>;
  affectedByDarkmode?: boolean;
}

export const svgConfig = ({
  size = "md",
  thickness = "normal",
  affectedByDarkmode = true,
  props = {},
}: SVGConfig): Omit<SVGProps<SVGElement>, "ref"> => {
  const { className: propClassName } = props;

  let thicknessClass = "";

  if (thickness === "thick") {
    thicknessClass = SVG_THICK.className;
  } else if (thickness === "thicker") {
    thicknessClass = SVG_THICKER.className;
  }

  const className = `${propClassName ?? ""} ${thicknessClass} ${
    affectedByDarkmode ? "" : "icon-no-colormode"
  }`;

  return {
    ...SVG_SIZE[size],
    ...props,
    className,
  };
};

import { useCallback, useContext } from "react";
import { ColorModeContext } from "../context/color-mode";

export const useColorMode = () => {
  const { setColorMode, colorMode } = useContext(ColorModeContext);

  const toggleColorMode = useCallback(() => {
    setColorMode(colorMode === "light" ? "dark" : "light");
  }, [colorMode, setColorMode]);

  return {
    toggleColorMode,
    setColorMode,
    colorMode,
    isDark: colorMode === "dark",
    isLight: colorMode === "light",
  };
};

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbTrend = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="bf8b92b5-dafb-44a4-932f-b378ba94dfaa" data-name="icon">
      <polyline
        points="9.7 3.51 20.49 3.51 20.49 14.3"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={20.03}
        y1={3.98}
        x2={8.04}
        y2={15.97}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbTrend);
export default Memo;

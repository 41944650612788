
import React, {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useRef,
} from "react";
import { Layout } from "react-grid-layout";
import { DRAGGABLE_HANDLE_CLASS } from "../../constants/grid";
import { NamedForwardRef } from "../../types/components";
import "./style.css";
export type LayoutDefinition = Omit<Layout, "i" | "x" | "y">;

export enum GridItemSize {
  MAIN = "main",
  SMALL = "small",
  BIG = "big",
  LONG = "long",
  WIDE = "wide",
}
export const GRID_ITEM_LAYOUTS: Record<GridItemSize, LayoutDefinition> = {
  [GridItemSize.MAIN]: {
    w: 1,
    h: 2,
    minH: 2,
    minW: 1,
    maxH: 2,
    maxW: 1,
  },
  [GridItemSize.SMALL]: {
    w: 1,
    h: 1,
    minH: 1,
    minW: 1,
    maxH: 1,
    maxW: 1,
  },
  [GridItemSize.BIG]: {
    w: 2,
    h: 2,
    minH: 2,
    minW: 2,
    maxH: 2,
    maxW: 2,
  },
  [GridItemSize.LONG]: {
    w: 1,
    h: 3,
    minH: 3,
    minW: 1,
    maxH: 3,
    maxW: 1,
  },
  [GridItemSize.WIDE]: {
    w: 2,
    h: 1,
    minH: 1,
    minW: 2,
    maxH: 1,
    maxW: 2,
  },
};

export interface GridItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  item: JSX.Element;
}
export type GridItemComponentType = NamedForwardRef<
  GridItemProps,
  HTMLDivElement
>;
const GridItemName = { displayName: "GridItem" };

const GridItemComp = React.memo(
  forwardRef<HTMLDivElement, GridItemProps>((props, ref) => {
    const { item, ...rest } = props;
    const innerRef = useRef<HTMLDivElement | null>(null);
    const setRef = useCallback(
      (el: HTMLDivElement | null) => {
        innerRef.current = el;
        if (typeof ref === "function") {
          ref(el);
        } else if (ref) {
          ref.current = el;
        }
      },
      [ref]
    );

    return (
      <div
        {...rest}
        className={`${DRAGGABLE_HANDLE_CLASS} ${rest.className ?? ""}`}
        ref={setRef}
      >
        {item}
      </div>
    );
  })
);
export const GridItem: GridItemComponentType = Object.assign(
  GridItemName,
  GridItemComp
);

import {
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  PointElement,
  CategoryScale,
  BarElement,
} from "chart.js";
import "./interpolate.js";
import "chartjs-adapter-date-fns";
import React from "react";
import ReactDOM from "react-dom";
import { renderIntoDocument } from "react-dom/test-utils/index.js";
import { createRoot } from "react-dom/client";
import "./tooltip";

ChartJS.register(
  BarElement,
  CategoryScale,
  LineController,
  LineElement,
  TimeScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip,
  Title
);

ChartJS.defaults.plugins.tooltip.intersect = true;

//ChartJS.defaults.animation = true;
ChartJS.defaults.hover.intersect = true;
//ChartJS.defaults.interaction.mode = "point";
ChartJS.defaults.font.family = "Manrope, sans-serif";
ChartJS.defaults.scales.time.ticks.maxRotation = 0;
ChartJS.defaults.scales.time.ticks.minRotation = 0;
ChartJS.defaults.scales.time.grid = {
  ...ChartJS.defaults.scales.time.grid,
  display: false,
};
ChartJS.defaults.scale.grid.drawBorder = false;
ChartJS.defaults.scales.time.time.displayFormats = {
  minute: "HH:mm",
  hour: "HH:mm",
  day: "dd.MM.yy",
  week: "dd.MM.yy",
  month: "dd.MM.yy",
  quarter: "ww",
  year: "ww",
};
ChartJS.defaults.datasets.line.tension = 0.2;
ChartJS.defaults.scales.time.ticks.autoSkip = true;
ChartJS.defaults.scales.time.ticks.autoSkipPadding = 10;

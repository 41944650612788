import React from "react";

export const filterChildren = (children: React.ReactNode, type: string) => {
  return React.Children.toArray(children).filter((child) => {
    if (React.isValidElement(child) && typeof child.type !== "string") {
      return (child.type as any).displayName === type;
    }
    return false;
  });
};
export const findChild = (children: React.ReactNode, type: string) => {
  return React.Children.toArray(children).find((child) => {
    if (React.isValidElement(child) && typeof child.type !== "string") {
      return (child.type as any).displayName === type;
    }
    return false;
  });
};

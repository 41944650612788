import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbZweirad = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="b4fdc7e1-6bc4-4eb0-9e3e-99c1911cbb89" data-name="icon">
      <circle
        cx={6}
        cy={15.53}
        r={3.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <circle
        cx={18}
        cy={15.53}
        r={3.5}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <line
        x1={12.5}
        y1={15.34}
        x2={18.5}
        y2={15.34}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={7.87}
        y1={10.03}
        x2={12.49}
        y2={15.34}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="5.89 15.34 9.18 4.97 11.18 4.97"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14,9.19h5.89a.94.94,0,0,1,.94.94v1.82a0,0,0,0,1,0,0H13.08a0,0,0,0,1,0,0V10.12A.94.94,0,0,1,14,9.19Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.08,11.94H9.54L7.66,9.85l.21-.66H10a3,3,0,0,1,3.06,2.75Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.81,7.62h.52A1.24,1.24,0,0,1,7.58,8.86v1a0,0,0,0,1,0,0H5.81a0,0,0,0,1,0,0V7.62A0,0,0,0,1,5.81,7.62Z"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbZweirad);
export default Memo;

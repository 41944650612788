import { HistoricalData } from "../api/types";

export enum StateboardErrorCode {
  NoData = "1001",
}

export class StateboardError extends Error {
  code: StateboardErrorCode;

  constructor(code: StateboardErrorCode, message?: string) {
    super(message);
    this.code = code;
  }
}

export class NoDataError extends StateboardError {
  constructor(message?: string) {
    super(StateboardErrorCode.NoData, message);
  }
}

export const throwOnNoData = (
  data: HistoricalData[],
  onlyAll: boolean = false
) => {
  let allEmpty = true;

  for (const hd of data) {
    const thisEmpty = hd.singleDataPoints.length <= 0;

    if (thisEmpty && !onlyAll) {
      throw new NoDataError("The historical data is empty");
    }
    allEmpty = thisEmpty && allEmpty;
  }
  if (allEmpty) {
    throw new NoDataError("The historical data is empty");
  }
  return true;
};

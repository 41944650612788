import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { defaultLineColor } from "../../../chartjs/util";
import { GridItemSize } from "../../grid/item";
import { StateBoard } from "../base";
import { StateboardComponent } from "../../../types/stateboard";
import { useStateboard } from "../../../hooks/useStateboard";
import { GRAPH_COLOR_ORDER } from "../../../constants/stateboards";
import {
  HistoricalData,
  SingleDataPoint,
  StateboardSorting,
} from "../../../api/types";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { HighlightValue } from "../../util/highlight-value";
import { useColorMode } from "../../../hooks/useColorMode";
import { numberToDotString } from "../../../util/number-strings";
import { TableList } from "../../util/table-list";
import { Point } from "../../util/point";
import { throwOnNoData } from "../../../util/throw-no-data";
import { sumLastHour } from "./util";
import { getTimeFrameBySorting } from "../../../api/utils";
import { Trend } from "../../util/trend";

interface PersonFrequncyMeausrementData {
  name: string;
  id: string;
  valueKey: string;
}

interface PersonFrequncyMeausrement {
  name: string;
  data: PersonFrequncyMeausrementData[];
}

const MEAUSERMENTS: PersonFrequncyMeausrement[] = [
  {
    name: "Schlossarkaden",
    data: [
      {
        id: "3bc2a1ae-8a6f-4cc6-aab0-0e7ac50f3077",
        name: "Arkaden",
        valueKey: "person.in",
      },
      {
        id: "3bc2a1ae-8a6f-4cc6-aab0-0e7ac50f3077",
        name: "Karlstraße",
        valueKey: "person.out",
      },
    ],
  },
  {
    name: "E.-Jaekle-Platz",
    data: [
      {
        name: "E.-Jaekle-Platz",
        id: "8752218d-1afc-468f-9f89-79576547798c",
        valueKey: "person.in",
      },
      {
        name: "Karlstraße",
        id: "8752218d-1afc-468f-9f89-79576547798c",
        valueKey: "person.out",
      },
    ],
  },
  {
    name: "Untere Fußgängerzone",
    data: [
      {
        name: "Nord",
        id: "ab81bd2b-4557-4b1d-8b58-8abfce00c5a0",
        valueKey: "person.in",
      },
      {
        name: "Süd",
        id: "ab81bd2b-4557-4b1d-8b58-8abfce00c5a0",
        valueKey: "person.out",
      },
    ],
  },
  {
    name: "Knöpfleswäscherin",
    data: [
      {
        name: "Nord",
        id: "71e09626-6b0d-42c4-91e1-1ddc59e03c0e",
        valueKey: "person.in",
      },
      {
        name: "Süd",
        id: "71e09626-6b0d-42c4-91e1-1ddc59e03c0e",
        valueKey: "person.out",
      },
      {
        name: "Rathaus",
        id: "95a79150-a49c-4580-8397-aa380cc596e3",
        valueKey: "person.in",
      },
    ],
  },
  {
    name: "Levillain Anlage",
    data: [
      {
        name: "Innenstadt",
        id: "hdh-pc-05",
        valueKey: "current.left_to_right",
      },
      {
        name: "Bahnhof",
        id: "hdh-pc-05",
        valueKey: "current.right_to_left",
      },
    ],
  },
];

interface PersonFrequncyData {
  name: string;
  data: {
    label: string;
    all: number;
    hourly: number;
  }[];
  singleDataPoints: HistoricalData["singleDataPoints"];
}

function getMeasurementData(data: HistoricalData[]) {
  const measurements = MEAUSERMENTS.map((measure) => {
    const groupedByTime: Record<string, Record<string, SingleDataPoint>> = {};

    MEAUSERMENTS.forEach((measure) => {
      groupedByTime[measure.name] = {};

      measure.data.forEach((measData) => {
        const hd = data.find((d) => d.labelIdentifier === measData.id)!;
        const singleDataPoints = hd.singleDataPoints.filter(
          (p) => p.valueKey === measData.valueKey
        );
        const thisGroupd = groupedByTime[measure.name];

        singleDataPoints.forEach((p) => {
          const time = p.datetime;
          if (!thisGroupd[time]) {
            thisGroupd[time] = { ...p };
            return;
          }

          thisGroupd[time].value = String(
            Number(thisGroupd[time].value) + Number(p.value)
          );
        });
      });
    });
    return {
      name: measure.name,
      data: measure.data.map((measData) => {
        const hd = data.find((d) => d.labelIdentifier === measData.id)!;
        const singleDataPoints = hd.singleDataPoints.filter(
          (p) => p.valueKey === measData.valueKey
        );
        return {
          label: measData.name,
          all: singleDataPoints.reduce(
            (acc, cur) => acc + Number(cur.value),
            0
          ),
          hourly: sumLastHour(singleDataPoints),
        };
      }),
      singleDataPoints: Object.values(groupedByTime[measure.name]).sort(
        (a, b) => {
          return (
            new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
          );
        }
      ),
    };
  });

  return measurements;
}

const Content = ({
  data,
  prevData,
  sorting,
}: {
  sorting: StateboardSorting;
  data: HistoricalData[];
  prevData: HistoricalData[];
}) => {
  throwOnNoData(data, true);
  const { isLight } = useColorMode();

  const chartConfig = React.useMemo(() => {
    return {
      responsive: true,

      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
        },
        y: {
          type: "linear",
        },
      },
    };
  }, []);

  const measurementsData = useMemo<PersonFrequncyData[]>(
    () => getMeasurementData(data),
    [data]
  );

  const measurementsDataPrev = useMemo<PersonFrequncyData[]>(
    () => getMeasurementData(prevData),
    [prevData]
  );

  const chartData = React.useMemo(() => {
    const dataSets = measurementsData.map((measurement, i) => {
      return {
        label: measurement.name,
        data: measurement.singleDataPoints.map((dat) => {
          return {
            x: new Date(dat.datetime),
            y: Number(dat.value) ?? NaN,
          };
        }),
        ...defaultLineColor(GRAPH_COLOR_ORDER[i]),
      };
    });

    const data = {
      datasets: dataSets,
    };

    return data;
  }, [measurementsData]);

  const lastHour = useMemo(() => {
    let sum = 0;

    measurementsData.forEach((measurement) => {
      measurement.data.forEach((d) => {
        sum += d.hourly;
      });
    });

    return sum;
  }, [measurementsData]);

  const columns = ["Standort", "Gesamt /h", "Laufrichtung"];

  const trendChange = useMemo(() => {
    const sum = measurementsData.reduce((acc, cur) => {
      return acc + cur.data.reduce((acc, cur) => acc + cur.all, 0);
    }, 0);

    const sumPrev = measurementsDataPrev.reduce((acc, cur) => {
      return acc + cur.data.reduce((acc, cur) => acc + cur.all, 0);
    }, 0);

    return ((sumPrev - sum) / -sumPrev) * 100;
  }, [measurementsData, measurementsDataPrev]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row justify-between  mb-8">
        <HighlightValue
          value={numberToDotString(lastHour)}
          label="Personen gesamte Standorte /h"
          color={isLight ? "dark" : "white"}
        />
        <Trend
          comparedTo={sorting}
          direction={trendChange > 0 ? "up" : "down"}
          value={trendChange.toFixed(1) + " %"}
        ></Trend>
      </div>
      <TableList className="text-xs" columsNames={columns}>
        {measurementsData.map((measurement, i) => {
          if (!measurement) {
            return null;
          }
          const empty = measurement.singleDataPoints.length === 0;
          const hourlySum =
            numberToDotString(
              measurement.data.reduce((acc, cur) => acc + cur.hourly, 0)
            ) ?? "-";

          const className = empty ? "opacity-50" : "";

          return (
            <TableList.Row
              className={
                "border-b border-b-inactive-light dark:border-b-inactive-dark " +
                className
              }
            >
              <TableList.Row.Item className="text-xs py-2">
                <div className="flex flex-row items-center">
                  <Point color={GRAPH_COLOR_ORDER[i]} className="mr-3" />
                  <span className="whitespace-nowrap">{measurement.name}</span>
                </div>
              </TableList.Row.Item>
              <TableList.Row.Item className="text-xs py-2">
                {empty ? "-" : hourlySum}
              </TableList.Row.Item>
              <TableList.Row.Item className="text-xs py-2">
                <div className="flex flex-col text-light whitespace-nowrap text-xs">
                  {measurement.data.map((d) => (
                    <div className="flex flex-row">
                      <span className="mr-auto">{d.label}:</span>
                      <span className="ml-2">
                        {empty ? "-" : numberToDotString(d.hourly)}
                      </span>
                    </div>
                  ))}
                </div>
              </TableList.Row.Item>
            </TableList.Row>
          );
        })}
      </TableList>
      <div className="h-full mt-4 flex flex-col pb-1.5">
        <div className="flex flex-row mb-2">
          <p className="mr-auto text-light text-sm font-bold">
            Gesamtpersonenaufkommen
          </p>
        </div>
        <div className="basis-full">
          <Line
            height="100%"
            options={chartConfig as any}
            data={chartData}
          ></Line>
        </div>
      </div>
    </div>
  );
};
const REFRESH_INTERVAL = 1000 * 60 * 15;
export const PersonFrequncyStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();

  const { sorting, setSorting, timeFrame } = useStateboard(REFRESH_INTERVAL);

  const request = {
    valueKey: [
      "person.in",
      "person.out",
      "current.right_to_left",
      "current.left_to_right",
    ].join(","),

    labelIdentifier:
      MEAUSERMENTS.flatMap((m) => m.data.map((d) => d.id)).join(",") +
      ",4d56e281-b89b-4be2-9468-2e70ceb6d876,hdh-pc05",
  };

  const { data, error, isLoading } = useHistoricalDataQuery({
    ...request,
    datetime__gte: timeFrame.start.toISOString(),
  });
  const prev = getTimeFrameBySorting(sorting, timeFrame.start);

  const {
    data: dataPrev,
    error: errorPrev,
    isLoading: isLoadingPrev,
  } = useHistoricalDataQuery({
    ...request,
    datetime__gte: prev.start.toISOString(),
    datetime__lte: prev.end.toISOString(),
  });

  return (
    <StateBoard
      size={GridItemSize.BIG}
      loading={(isLoading && !data) || (isLoadingPrev && !dataPrev)}
      error={!!error || !!errorPrev}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title title={t("PEOPLE_FREQUENCY_STATEBOARD_TITLE")} />
      <StateBoard.Content>
        {data && dataPrev && (
          <Content sorting={sorting} data={data} prevData={dataPrev} />
        )}
      </StateBoard.Content>
      {/* <StateBoard.Select /> */}
    </StateBoard>
  );
};

export const numberToDotString = (
  number: number,
  delimiter = ".",
  decimalDelimiter = ","
) => {
  const string = number.toString();
  const parts = string.split(".");

  return (
    parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter) +
    (parts[1] ? decimalDelimiter + parts[1] : "")
  );
};

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbNurBewoelktColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="b5a5c099-91c6-4996-95dd-cf229bad8aa3"
        cx={9.88}
        cy={12.48}
        r={6.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#a0cbcc" stopOpacity={0.8} />
        <stop offset={0.16} stopColor="#a0cbcc" stopOpacity={0.78} />
        <stop offset={0.31} stopColor="#a0cbcc" stopOpacity={0.72} />
        <stop offset={0.47} stopColor="#a0cbcc" stopOpacity={0.62} />
        <stop offset={0.63} stopColor="#a0cbcc" stopOpacity={0.49} />
        <stop offset={0.78} stopColor="#a0cbcc" stopOpacity={0.31} />
        <stop offset={0.94} stopColor="#a0cbcc" stopOpacity={0.09} />
        <stop offset={1} stopColor="#a0cbcc" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="b54c2ef1-ba2f-4773-b6f2-ac8bf996a13b"
        cx={16.75}
        cy={10.82}
        r={4.43}
        xlinkHref="#b5a5c099-91c6-4996-95dd-cf229bad8aa3"
      />
    </defs>
    <g id="b0812a40-2f3d-481f-87c0-87e26a11f3a5" data-name="color">
      <path
        d="M14.6,10.67a3.61,3.61,0,0,0-7.13-.1H5.92a3.42,3.42,0,0,0,0,6.83h7.91a3.41,3.41,0,0,0,.77-6.73Z"
        fill="url(#b5a5c099-91c6-4996-95dd-cf229bad8aa3)"
      />
      <path
        d="M12,7.92a3,3,0,0,1,5.35,1.32h1.29a2.84,2.84,0,0,1,0,5.68H17.08s.62-2.28-.7-3.26a5.94,5.94,0,0,0-2-1.1,4.86,4.86,0,0,0-.66-1.76A6,6,0,0,0,12,7.92Z"
        fill="url(#b54c2ef1-ba2f-4773-b6f2-ac8bf996a13b)"
      />
    </g>
    <g id="f61be09e-7230-4c66-9891-a776686a3cf3" data-name="icon">
      <path
        d="M14.6,10.67a3.61,3.61,0,0,0-7.13-.1H5.92a3.42,3.42,0,0,0,0,6.83h7.91a3.41,3.41,0,0,0,.77-6.73Z"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path
        d="M12,7.79a3,3,0,0,1,5.35,1.32h1.29a2.84,2.84,0,1,1,0,5.68H17.08"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbNurBewoelktColor);
export default Memo;

import maplibregl, { MarkerOptions } from "maplibre-gl";
import { JSXElementConstructor, ReactElement, useCallback } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const TMP_ID = "tmp";

export const useMarkerFactory = () => {
  const createMarker = useCallback(
    (
      element: ReactElement<any, string | JSXElementConstructor<any>>,
      options: Omit<MarkerOptions, "element"> = {}
    ) => {
      if (!options) {
        options = {};
      }
      const tmpDiv = document.getElementById(TMP_ID)!;
      tmpDiv.insertAdjacentHTML("beforeend", renderToStaticMarkup(element));
      const markerElement = tmpDiv.lastChild as HTMLElement;
      const res = new maplibregl.Marker({
        element: markerElement,
        ...options,
      });
      markerElement.remove();
      return res;
    },
    []
  );

  return createMarker;
};

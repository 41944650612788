import { useEffect, useState } from "react";
import { Breakpoint, BREAKPOINTS } from "../constants/breakpoints";

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint | "xs">("sm");

  useEffect(() => {
    const onResize = () => {
      let matched = false;
      for (const [key, val] of Object.entries(BREAKPOINTS).reverse()) {
        const breakpointKey = key as Breakpoint;
        if (window.matchMedia(`(min-width: ${val})`).matches) {
          matched = true;
          if (breakpointKey !== breakpoint) {
            setBreakpoint(breakpointKey);
          }

          break;
        }
      }

      if (!matched) {
        if ("xs" !== breakpoint) {
          setBreakpoint("xs");
        }
      }
    };
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [breakpoint]);

  return breakpoint;
};

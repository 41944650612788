import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { SVG_SIZE, svgConfig } from "../../../constants/svg-size";
import { ScColor } from "../../../util/color";
import { HighlightValue } from "../../util/highlight-value";
import {
  ScDbArrowDown,
  ScDbArrowUp,
  ScDbAuto,
  ScDbBus,
  ScDbLkw,
  ScDbVan,
  ScDbZweirad,
  ScIconsBike,
} from "../../icons";
import { TableList } from "../../util/table-list";
import { Trend, TrendIcon, TrendComparedTo } from "../../util/trend";
import { StateBoard } from "../base";
import { defaultLineColor } from "../../../chartjs/util";
import { useColorMode } from "../../../hooks/useColorMode";
import { numberToDotString } from "../../../util/number-strings";
import { Point } from "../../util/point";
import { StateboardComponent } from "../../../types/stateboard";
import { useStateboard } from "../../../hooks/useStateboard";
import { getTimeFrameBySorting } from "../../../api/utils";
import {
  AggregateItem,
  AggregateResponse,
  HistoricalData,
  SingleDataPoint,
  StateboardSorting,
} from "../../../api/types";
import {
  useAggregateHistoricalDataQuery,
  useHistoricalDataQuery,
} from "../../../api/hooks";
import { throwOnNoData } from "../../../util/throw-no-data";

const CLICHYSTRASSE = "841db5c7-fd00-470c-8854-e65e52b3c010"; // "22ac61b5-6437-4e47-bc20-e98a0c264c6c";
const WILLHELMSTRASSE = "83e9725b-2994-49c9-9e6c-b12eb5250809"; // "c71c01bd-97ec-4c79-ad03-f179501ec296";

export enum VehicleType {
  CAR = "car",
  TRUCK = "truck",
  BICYCLE = "bicycle",
  BUS = "bus",
  VAN = "van",
  BIKE = "motorbike",
}

export const getIconForVehicle = (
  vehicleType: VehicleType,
  props: Omit<React.SVGProps<SVGSVGElement>, "ref">
) => {
  switch (vehicleType) {
    case VehicleType.CAR:
      return <ScDbAuto {...props} />;
    case VehicleType.TRUCK:
      return <ScDbLkw {...props} />;
    case VehicleType.BIKE:
      return <ScDbZweirad {...props} />;
    case VehicleType.VAN:
      return <ScDbVan {...props} />;
    case VehicleType.BICYCLE:
      return <ScIconsBike {...props} />;
    case VehicleType.BUS:
      return <ScDbBus {...props} />;
    default:
      return <ScDbAuto {...props} />;
  }
};

export interface TrendValues {
  direction: "down" | "up" | "equal";
  value: number;
}

export interface Vehicle {
  count: number;
  type: VehicleType;
  color: ScColor;
  trend: TrendValues;
  points: { x: Date; y: number }[];
}

const Content = ({
  data,
  sorting,
}: {
  data: HistoricalData[];
  sorting: StateboardSorting;
}) => {
  const { t } = useTranslation();

  throwOnNoData(data);

  const orderedPoints = useMemo(() => {
    const types = Object.values(VehicleType);

    const orderedPoints = types.reduce<
      Record<VehicleType, Record<string, number>>
    >((res, cur) => {
      res[cur] = {};
      return res;
    }, {} as any);

    // the lowest date out of all historicals
    const timeFrameStart = data.reduce((res, h) => {
      const cur = Date.parse(h.singleDataPoints.at(-1)!.datetime);
      if (cur < res) {
        return cur;
      }
      return res;
    }, Infinity);

    const timeFrameEnd = new Date().getTime();
    const BUCKETS = 300;
    const frequency = (timeFrameEnd - timeFrameStart) / BUCKETS;
    let lastAdded = timeFrameStart;
    // Resample the data into 300 datapoints
    //TODO: remove once the backend can resample
    data.forEach((h) => {
      lastAdded = timeFrameStart;
      h.singleDataPoints
        .sort((a, b) => Date.parse(a.datetime) - Date.parse(b.datetime))
        .forEach((p) => {
          const type = p.valueKey.split(".")[0] as VehicleType;
          if (!types.includes(type)) return;

          const diff = Date.parse(p.datetime) - lastAdded;

          if (diff > frequency) {
            lastAdded = lastAdded + Math.floor(diff / frequency) * frequency;
          }

          const bucketTime = new Date(lastAdded).toISOString();

          if (!(bucketTime in orderedPoints[type])) {
            orderedPoints[type][bucketTime] = 0;
          }

          orderedPoints[type][bucketTime] += Number(p.value);
        });
    });
    return orderedPoints;
  }, [data]);

  const aggregation = useMemo(() => {
    const result: Record<string, { result: number }> = {};

    const aggreagateHistoricals = (
      historical: HistoricalData[],
      suffix: string
    ) => {
      historical.forEach((hd) => {
        hd.singleDataPoints.forEach((p) => {
          const key = `${p.valueKey.split(".")[0]}${suffix}`;

          if (!(key in result)) {
            result[key] = {
              result: 0,
            };
          }
          result[key].result += Number(p.value);
        });
      });
    };

    aggreagateHistoricals(
      data.filter((hd) => hd.labelIdentifier === WILLHELMSTRASSE),
      "_will_sum"
    );
    aggreagateHistoricals(
      data.filter((hd) => hd.labelIdentifier === CLICHYSTRASSE),
      "_clichy_sum"
    );
    aggreagateHistoricals(data, "_sum");

    return { result };
  }, [data]);

  const vehicles = useMemo(() => {
    const result: Vehicle[] = [
      {
        type: VehicleType.CAR,
        count: aggregation.result.car_sum.result,
        color: "pink",
        trend: {
          direction: "equal",
          value: 0,
        },
        points: [],
      },
      {
        type: VehicleType.BIKE,
        count: aggregation.result.motorbike_sum.result,
        color: "turquoise",
        trend: {
          direction: "equal",
          value: 0,
        },
        points: [],
      },
      {
        type: VehicleType.BUS,
        count: aggregation.result.bus_sum.result,
        color: "purple",
        trend: {
          direction: "equal",
          value: 0,
        },
        points: [],
      },
      {
        type: VehicleType.TRUCK,
        count: aggregation.result.truck_sum.result,
        color: "orange",
        trend: {
          direction: "equal",
          value: 0,
        },
        points: [],
      },
      {
        type: VehicleType.VAN,
        count: aggregation.result.van_sum.result,
        color: "bright-green",
        trend: {
          direction: "equal",
          value: 0,
        },
        points: [],
      },
      {
        type: VehicleType.BICYCLE,
        count: aggregation.result.bicycle_sum.result,
        color: "blue",
        trend: {
          direction: "equal",
          value: 0,
        },
        points: [],
      },
    ];

    return result;
  }, [aggregation.result]);

  const totalVehicleCount = useMemo(() => {
    return Object.values(VehicleType).reduce((acc, key) => {
      return acc + aggregation.result[`${key}_sum`].result;
    }, 0);
  }, [aggregation.result]);

  const { colorMode } = useColorMode();

  const columns = useMemo(() => {
    return [
      t("TRAFFIC_STATEBOARD_VEHICLE_CLASS"),
      "Gesamt",
      "Wilhelmstr.",
      "Clichystr.",
    ];
  }, [t]);

  const chartConfig = React.useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
        },
        y: {
          type: "linear",
        },
      },
    };
  }, []);

  const chartData = React.useMemo(() => {
    return {
      datasets: vehicles.map((vehicle) => {
        return {
          label: t("TRAFFIC_STATEBOARD_VEHICLE_" + vehicle.type.toUpperCase()),
          data: Object.entries(orderedPoints[vehicle.type])
            .map(([key, val]) => {
              const timestamp = Date.parse(key);
              const date = new Date(timestamp);
              return {
                x: date,
                y: val,
              };
            })
            .sort((a, b) => a.x.getTime() - b.x.getTime()),
          ...defaultLineColor(vehicle.color),
        };
      }),
    };
  }, [orderedPoints, t, vehicles]);

  return (
    <div className="flex flex-col h-full">
      <div>
        <div className="flex flex-row justify-between mb-11">
          <HighlightValue
            value={numberToDotString(Math.round(totalVehicleCount))}
            label="Fahrzeuge gesamt"
            color={colorMode === "light" ? "dark" : "white"}
          />
          <div className="flex flex-col text-xs ">
            <div className="flex flex-row items-center mb-1">
              <ScDbArrowDown {...svgConfig({ size: "md" })} />
              <span className="ml-2 mr-4 text-light">Wilhelmstraße</span>
              <span className="ml-auto font-bold">
                {Object.values(VehicleType).reduce((acc, key) => {
                  return acc + aggregation.result[`${key}_will_sum`].result;
                }, 0)}
              </span>
            </div>
            <div className="flex flex-row items-center mb-1">
              <ScDbArrowUp {...svgConfig({ size: "md" })} />
              <span className="ml-2 mr-4 text-light">Clichystraße</span>
              <span className="ml-auto font-bold">
                {Object.values(VehicleType).reduce((acc, key) => {
                  return acc + aggregation.result[`${key}_clichy_sum`].result;
                }, 0)}
              </span>
            </div>
          </div>
        </div>
        <div className="text-xs">
          <TableList columsNames={columns}>
            {vehicles.map((vehicle) => (
              <TableList.Row key={vehicle.type}>
                <TableList.Row.Item className="py-1.5">
                  <div className="flex flex-row items-center">
                    <Point className="mr-2" color={vehicle.color} />
                    {getIconForVehicle(vehicle.type, { ...SVG_SIZE.xl })}
                    <span className="ml-2">
                      {t(
                        "TRAFFIC_STATEBOARD_VEHICLE_" +
                          vehicle.type.toUpperCase()
                      )}
                    </span>
                  </div>
                </TableList.Row.Item>
                <TableList.Row.Item className="!text-left">
                  <span className="font-bold text-left">
                    {numberToDotString(Math.round(vehicle.count))}
                  </span>
                </TableList.Row.Item>
                <TableList.Row.Item>
                  <span className=" text-left text-light">
                    {numberToDotString(
                      Math.round(
                        aggregation.result[`${vehicle.type}_will_sum`].result
                      )
                    )}
                  </span>
                </TableList.Row.Item>
                <TableList.Row.Item>
                  <span className=" text-left text-light">
                    {numberToDotString(
                      Math.round(
                        aggregation.result[`${vehicle.type}_clichy_sum`].result
                      )
                    )}
                  </span>
                </TableList.Row.Item>
              </TableList.Row>
            ))}
          </TableList>
        </div>
      </div>
      <div className="mt-7.5 flex-grow basis-full flex flex-col ">
        <h3 className="text-light text-sm mb-3 font-bold">
          {t("TRAFFIC_STATEBOARD_CHART_TITLE")}
        </h3>
        <div className="h-full pb-1.5">
          <Line data={chartData} options={chartConfig as any}></Line>
        </div>
      </div>
    </div>
  );
};

export const TrafficStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();

  const { sorting, setSorting, timeFrame } = useStateboard();

  const timeFrameStart = new Date(timeFrame.start.getTime());
  const timeFrameEnd = new Date(timeFrame.end.getTime());
  const {
    isLoading: isLoadingData,
    data,
    isError: isErrorData,
  } = useHistoricalDataQuery({
    datetime__gte: timeFrameStart.toISOString(),
    datetime__lte: timeFrameEnd.toISOString(),
    labelIdentifier: [WILLHELMSTRASSE, CLICHYSTRASSE].join(","),
    valueKey: Object.values(VehicleType)
      .map((key) => `${key}.in,${key}.out`)
      .join(","),
  });

  return (
    <StateBoard
      error={isErrorData}
      loading={isLoadingData}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title
        title={t("TRAFFIC_STATEBOARD_TITLE")}
      ></StateBoard.Title>
      <StateBoard.Content>
        {data && <Content data={data} sorting={sorting} />}
      </StateBoard.Content>
      {/* <StateBoard.Select></StateBoard.Select> */}
    </StateBoard>
  );
};

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  aggregateHistoricalData,
  getHistoricalData,
  parseParams,
} from "./requests";
import {
  HistoricalAggregateBody,
  HistoricalData,
  HistoricalDataParams,
  SingleDataPoint,
} from "./types";
// default refetch interval in ms
const DEFAULT_INTERVAL = 1000 * 15;

const getYoungestSingledataPoint = (data: HistoricalData[]) => {
  let oldestTime = Infinity;

  data.forEach((hd) => {
    const cur = getYoungestDate(hd);
    if (cur < oldestTime) {
      oldestTime = cur;
    }
  });
  return oldestTime;
};

const getOldestSingledataPoint = (data: HistoricalData[]) => {
  let youngestTime = -Infinity;

  data.forEach((hd) => {
    const cur = getOldestDate(hd);
    if (cur > youngestTime) {
      youngestTime = cur;
    }
  });
  return youngestTime;
};
const getYoungestDate = (hd: HistoricalData) => {
  const time = hd.singleDataPoints.at(0)?.datetime;

  return time ? Date.parse(time) : Infinity;
};
const getOldestDate = (hd: HistoricalData) => {
  const time = hd.singleDataPoints.at(-1)?.datetime;

  return time ? Date.parse(time) : -Infinity;
};

const joinHistoricalDatas = (...data: HistoricalData[]) => {
  const result: Record<string, HistoricalData> = {};
  const uniquePoints: Record<string, Record<string, SingleDataPoint>> = {};

  const pointsToUnique = (ps: SingleDataPoint[]) => {
    return ps.reduce<Record<string, SingleDataPoint>>((acc, cur) => {
      acc[cur.datetime + cur.valueKey] = cur;
      return acc;
    }, {});
  };

  data.forEach((hd) => {
    if (!(hd.id in result)) {
      result[hd.id] = hd;
      uniquePoints[hd.id] = pointsToUnique(hd.singleDataPoints);
      return;
    }
    uniquePoints[hd.id] = {
      ...uniquePoints[hd.id],
      ...pointsToUnique(hd.singleDataPoints),
    };
  });

  return Object.values(result).map((hd) => {
    hd.singleDataPoints = Object.values(uniquePoints[hd.id]).sort(
      (a, b) => Date.parse(b.datetime) - Date.parse(a.datetime)
    );
    return hd;
  });
};

const clampData = (
  hd: HistoricalData,
  gte: number | undefined,
  lte: number | undefined
) => {
  if (gte && lte) {
    if (gte > lte) {
      throw new Error("Gte has to be smaller than lte");
    }
  }

  let lteFound = !lte;
  let startIndex = 0;
  let endIndex = hd.singleDataPoints.length;

  for (let i = 0; i < hd.singleDataPoints.length; i++) {
    const dp = hd.singleDataPoints[i];
    const cur = Date.parse(dp.datetime);

    if (lte && !lteFound) {
      if (cur <= lte) {
        startIndex = i;
        lteFound = true;
      }
      if (!gte) {
        break;
      }
    }
    if (gte && lteFound) {
      if (cur <= gte) {
        endIndex = i;
        break;
      }
    }
  }
  hd.singleDataPoints = hd.singleDataPoints.slice(startIndex, endIndex);
};
interface CacheMissing {
  gte?: number;
  lte?: number;
}

export const finiteOrUndefined = (num: number) =>
  Number.isFinite(num) ? num : undefined;

export const useHistoricalDataQuery = (
  params: HistoricalDataParams,
  options?: UseQueryOptions
) => {
  const lastParams = useRef<HistoricalDataParams | undefined>();
  const lastData = useRef<HistoricalData[]>();
  const [cache, setCache] = useState<HistoricalData[] | undefined>();

  const shouldRefetchAll = useCallback((): boolean => {
    if (!lastParams) {
      return true;
    }
    return (
      lastParams.current?.groupIdentifier !== params?.groupIdentifier ||
      lastParams.current?.labelIdentifier !== params?.labelIdentifier ||
      lastParams.current?.valueKey !== params?.valueKey
    );
  }, [params?.groupIdentifier, params?.labelIdentifier, params?.valueKey]);

  const getMissings = useCallback(
    (lte: number, gte: number): CacheMissing[] => {
      const paramMissing = {
        gte: !!params.datetime__gte
          ? Date.parse(params.datetime__gte)
          : undefined,
        lte: !!params.datetime__lte
          ? Date.parse(params.datetime__lte)
          : undefined,
      };

      if (!cache) {
        return [paramMissing];
      }
      const youngest = getYoungestSingledataPoint(cache);
      const oldest = getOldestSingledataPoint(cache);

      const missings: CacheMissing[] = [];
      // no overlap
      if (gte > youngest) {
        return [paramMissing];
      }
      // no overlap
      if (lte < oldest) {
        return [paramMissing];
      }

      if (gte < oldest) {
        const missingBefore: CacheMissing = {};
        missingBefore.gte = Number.isFinite(gte) ? gte : undefined;
        missingBefore.lte = oldest;
        missings.push(missingBefore);
      }

      if (lte > youngest) {
        const missingAfter: CacheMissing = {};
        missingAfter.gte = youngest;
        missingAfter.lte = Number.isFinite(lte) ? lte : undefined;
        missings.push(missingAfter);
      }

      return missings;
    },
    [cache, params.datetime__gte, params.datetime__lte]
  );

  const query = useCallback(async () => {
    const shouldRefetch = shouldRefetchAll();
    lastParams.current = { ...params };

    if (shouldRefetch || !cache) {
      const res = await getHistoricalData(params);
      setCache(res);
      return res;
    }

    const lte = params.datetime__lte
      ? Date.parse(params.datetime__lte)
      : Infinity;
    const gte = params.datetime__gte
      ? Date.parse(params.datetime__gte)
      : -Infinity;

    const missings = getMissings(lte, gte);

    const requests = missings.map((m) => {
      const newParams: HistoricalDataParams = {
        ...params,
      };

      if (m.gte && Number.isFinite(m.gte)) {
        newParams.datetime__gte = new Date(m.gte).toISOString();
      } else {
        delete newParams.datetime__gte;
      }
      if (m.lte && Number.isFinite(m.lte)) {
        newParams.datetime__lte = new Date(m.lte).toISOString();
      } else {
        delete newParams.datetime__lte;
      }

      return getHistoricalData(newParams);
    });

    const response = await Promise.all(requests);
    const joined = joinHistoricalDatas(...response.flat(), ...cache);

    // why does js not have a build in deep clone?
    const newCache = JSON.parse(JSON.stringify(joined));

    // only get data we need
    joined.forEach((hd) =>
      clampData(hd, finiteOrUndefined(gte), finiteOrUndefined(lte))
    );

    setCache(newCache);

    return joined;
  }, [cache, getMissings, params, shouldRefetchAll]);

  const queryResult = useQuery(
    ["historicalData", ...Object.values(params)],
    query,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...(options as any),
    }
  );

  lastData.current = queryResult.data;

  return queryResult;
};

export const useAggregateHistoricalDataQuery = (
  params: HistoricalDataParams,
  body: HistoricalAggregateBody,
  options?: UseQueryOptions
) => {
  const query = useCallback(() => {
    return aggregateHistoricalData(params, body);
  }, [body, params]);

  const queryResult = useQuery(
    [`historicalDataAggregate" + "/${JSON.stringify(body)}`],
    query,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...(options as any),
    }
  );
  return queryResult;
};

import { Layout } from "react-grid-layout";

export type LayoutDefinition = Omit<Layout, "i" | "x" | "y">;

export enum GridItemSize {
  MAIN = "main",
  SMALL = "small",
  BIG = "big",
  LONG = "long",
  WIDE = "wide",
}
 
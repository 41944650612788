import { Chart as ChartJS, Tooltip, PointElement } from "chart.js";
import "./interpolate.js";
import "chartjs-adapter-date-fns";
import React from "react";
import ReactDOM from "react-dom";
import { renderIntoDocument } from "react-dom/test-utils/index.js";
import { createRoot } from "react-dom/client";

ChartJS.register(PointElement, Tooltip);

interface ChartTooltipProps {
  title: string;
  left: number;
  right: number;
  top: number;
  overHalf: boolean;
  points: {
    text: string;
    color: string;
  }[];
}

const ChartTooltip = ({
  title,
  top,
  left,
  right,
  points,
  overHalf,
}: ChartTooltipProps) => {
  const style: React.CSSProperties = {
    top,
    left: overHalf ? left + 10 : `unset`,
    right: !overHalf ? right + 10 : `unset`,
  };

  return (
    <div
      style={style}
      className="absolute text-sm px-4 py-3 dark:bg-bg-secondary-dark bg-bg-secondary-light rounded-md dark:shadow-md-dark shadow-md-light"
    >
      <span>{title}</span>
      <div className="flex flex-col w-full mb-1.5"></div>
      {points.map((p) => {
        const [label, ...values] = p.text.split(" ");
        const value = values.join(" ");
        return (
          <div
            key={p.text + p.color}
            className="flex flex-row items-center w-full"
          >
            <div>
              <div
                className={`block rounded-full h-1.5 w-1.5 relative`}
                style={{ background: p.color }}
              >
                <div
                  className={`block rounded-full h-1.5 w-1.5 absolute top-0 left-0 blur-sm`}
                  style={{ background: p.color }}
                ></div>
              </div>
            </div>

            <div className="flex ml-2 flex-row items-center w-full justify-between">
              <span>{label}</span>
              <span className="ml-2">{value}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

ChartJS.defaults.plugins.tooltip.enabled = false;
const tooltipRootEl = document.getElementById("chart-tooltips")!;
const tooltipRoot = createRoot(tooltipRootEl);

const tooltips: Record<string, JSX.Element | null> = {};

function renderTooltips() {
  tooltipRoot.render(<>{Object.values(tooltips)}</>);
}

ChartJS.defaults.plugins.tooltip.external = function external(context) {
  const buildTooltip = (): JSX.Element | null => {
    if (context.tooltip.opacity === 0) {
      return null;
    }
    const chartPos = context.chart.canvas.getBoundingClientRect();
    const rootRect = tooltipRootEl.getBoundingClientRect();

    const offsetLeft = chartPos.left + this.caretX;
    const offsetRight =
      rootRect.width - chartPos.right + (chartPos.width - this.caretX);

    const overHalf = offsetLeft < chartPos.left + chartPos.width / 2;

    const offsetTop = chartPos.top + chartPos.height / 4;

    const bodyLines = this.body.map((b) => b.lines);
    const points = bodyLines.map((line, i) => {
      return {
        color: this.labelColors[i].borderColor.toString(),
        text: line.join(" "),
      };
    });

    return (
      <ChartTooltip
        overHalf={overHalf}
        right={offsetRight}
        points={points}
        key={context.chart.id}
        top={offsetTop}
        left={offsetLeft}
        title={this.title.join(" ")}
      />
    );
  };

  tooltips[context.chart.id] = buildTooltip();
  renderTooltips();
};

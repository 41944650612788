import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbGarbageYellow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="ff95395a-2eed-4b9f-8af0-46ba8fca81a6"
        cx={11.81}
        cy={12}
        r={6.47}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f4c914" stopOpacity={0.8} />
        <stop offset={0.12} stopColor="#f4c914" stopOpacity={0.73} />
        <stop offset={0.37} stopColor="#f4c914" stopOpacity={0.55} />
        <stop offset={0.71} stopColor="#f4c914" stopOpacity={0.26} />
        <stop offset={1} stopColor="#f4c914" stopOpacity={0} />
      </radialGradient>
    </defs>
    <g id="e71ed20e-4b77-4caf-a259-35c4972cff90" data-name="color">
      <path
        d="M14.54,7.39V5.5a1,1,0,0,0-1-1H10.07a1,1,0,0,0-1,1V7.39H6.56V18.5a1,1,0,0,0,1,1h8.5a1,1,0,0,0,1-1V7.39Z"
        fill="url(#ff95395a-2eed-4b9f-8af0-46ba8fca81a6)"
      />
    </g>
    <g id="a67091b0-425b-4163-b29f-d6b6e1daea67" data-name="icon">
      <path
        d="M6.56,7.39h10.5a0,0,0,0,1,0,0V18.5a1,1,0,0,1-1,1H7.56a1,1,0,0,1-1-1V7.39A0,0,0,0,1,6.56,7.39Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <line
        x1={4.6}
        y1={7.36}
        x2={19.02}
        y2={7.36}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        d="M10.07,4.5h3.47a1,1,0,0,1,1,1V7.36a0,0,0,0,1,0,0H9.07a0,0,0,0,1,0,0V5.5A1,1,0,0,1,10.07,4.5Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <line
        x1={10.06}
        y1={10.38}
        x2={10.06}
        y2={16.64}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <line
        x1={13.55}
        y1={10.38}
        x2={13.55}
        y2={16.64}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbGarbageYellow);
export default Memo;

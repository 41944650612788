import { useTranslation } from "react-i18next";
import { StateBoard } from "../base";
import { Map } from "../../util/map/map";
import React, { useEffect, useMemo } from "react";
import { useMarkerFactory } from "../../../hooks/useMarkerFactory";
import { ParkingPlaceMarker } from "../../util/map/marker/parkingplace-marker";
import {
  ScDbBehindertenparkplatz,
  ScDbElectro,
  ScDbAusrufezeichen,
  ScDbBlitz,
  ScDbTaxiparkplatz,
  ScDbKurzzeitparkplatz,
  ScDbElektroparkplatz,
  ScDbParkenBig,
} from "../../icons";
import { Sentiment, StateboardComponent } from "../../../types/stateboard";
import { getMarkersBoundingBox } from "../../util/map/marker/util";
import { useStateboard } from "../../../hooks/useStateboard";
import { HistoricalData } from "../../../api/types";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { throwOnNoData } from "../../../util/throw-no-data";
import { MapMarker, MapMarkerProps } from "../../util/map/marker/marker";
import { svgConfig } from "../../../constants/svg-size";

interface ParkingPlace {
  latitude: number;
  longitude: number;
  name: string;
  status?: string;
}

interface ParkingPlaceGroup {
  centerLatitude: number;
  centerLongitude: number;
  parkingPlaces: ParkingPlace[];
}

function groupParkingPlacesByLocation(
  parkingPlaces: ParkingPlace[],
  distanceThreshold: number
): ParkingPlaceGroup[] {
  const groups: ParkingPlaceGroup[] = [];

  for (let i = 0; i < parkingPlaces.length; i++) {
    const currentPlace = parkingPlaces[i];
    let currentGroup: ParkingPlaceGroup | undefined;
    let debugKirche = false;
    if (currentPlace.name.includes("kirche")) {
      console.log(currentPlace);
      debugKirche = true;
    }
    for (let j = 0; j < groups.length; j++) {
      if (isWithinGroupDistance(groups[j], currentPlace, distanceThreshold)) {
        currentGroup = groups[j];
        break;
      }
    }

    if (!currentGroup) {
      if (debugKirche) console.log("new group");
      currentGroup = {
        centerLatitude: currentPlace.latitude,
        centerLongitude: currentPlace.longitude,
        parkingPlaces: [currentPlace],
      };
      groups.push(currentGroup);
    } else {
      if (debugKirche) console.log("add to group", currentGroup);

      currentGroup.parkingPlaces.push(currentPlace);
      // Math
      currentGroup.centerLatitude =
        (currentGroup.centerLatitude * (currentGroup.parkingPlaces.length - 1) +
          currentPlace.latitude) /
        currentGroup.parkingPlaces.length;
      currentGroup.centerLongitude =
        (currentGroup.centerLongitude *
          (currentGroup.parkingPlaces.length - 1) +
          currentPlace.longitude) /
        currentGroup.parkingPlaces.length;
    }
  }

  return groups;
}

function isWithinGroupDistance(
  group: ParkingPlaceGroup,
  place: ParkingPlace,
  distanceThreshold: number
): boolean {
  for (let i = 0; i < group.parkingPlaces.length; i++) {
    const distance = Math.sqrt(
      Math.pow(group.parkingPlaces[i].latitude - place.latitude, 2) +
        Math.pow(group.parkingPlaces[i].longitude - place.longitude, 2)
    );
    if (distance > distanceThreshold) {
      return false;
    }
  }
  return true;
}

type ParkingPlaceGroupStatus = "free" | "some" | "occupied" | "unknown";

const NEUTRAL_SENTIMENT_THRESHOLD = 0.8;

function groupStatus(group: ParkingPlaceGroup): ParkingPlaceGroupStatus {
  const status = group.parkingPlaces.map<ParkingPlaceGroupStatus>(
    (p) => (p.status ?? "unknown") as ParkingPlaceGroupStatus
  );

  // count up different status amounts

  const statusAmounts = status.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: (acc[cur] ?? 0) + 1,
    }),
    {} as Record<ParkingPlaceGroupStatus, number>
  );

  if (statusAmounts.unknown === status.length) {
    return "unknown";
  }
  const occupied = statusAmounts.occupied + statusAmounts.unknown;

  if (occupied === status.length) {
    return "occupied";
  }

  if (occupied / status.length > NEUTRAL_SENTIMENT_THRESHOLD) {
    return "some";
  }

  return "free";
}

const GROUP_DISTNACE_THRESHOLD_KM = 0.15;
// 1km in lat/long
const GROUP_DISTNACE_THRESHOLD = GROUP_DISTNACE_THRESHOLD_KM / 111.12;

const occupiedCount = (places: ParkingPlace[]) => {
  return places.filter((p) => p.status === "occupied").length;
};

function ParkingMarkerItem({
  icon,
  places,
}: {
  icon: JSX.Element | null;
  places: ParkingPlace[];
}) {
  if (places.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-row items-center justify-center w-full z-10 py-0.5 border-t border-inactive-dark first:border-t-0">
      <div className="mr-1">{icon}</div>
      <p className="block mr-auto text-light">
        <span className="text-white font-bold">{occupiedCount(places)}</span>{" "}
        von {places.length} belegt
      </p>
    </div>
  );
}
const PADDING = {
  bottom: 60,
  left: 60,
  right: 60,
  top: 60,
};
const Content = ({ data }: { data: HistoricalData[] }) => {
  throwOnNoData(data, true);

  const parkingGroups = useMemo(() => {
    const parkingPlaces = data
      .filter((d) => !!d.latitude && !!d.longitude)
      .filter(
        (d) =>
          d.labelIdentifier !== "hdh-pk11" && d.labelIdentifier !== "hdh-pk12"
      )
      .flatMap((d) => {
        // Smart Vision Parking
        if (d.groupIdentifier === "1199096d-cc09-4d42-b34d-429e45a53310") {
          if (d.labelIdentifier === "91ef116c-ef8f-4a01-900a-fac70091b4da") {
            return [];
          }

          const capacity = Number(d.misc.capacity);
          const occupied = Number(
            d.singleDataPoints.filter((p) => p.valueKey === "vehicles")[0]
              ?.value ?? 0
          );
          const res = Array.from({ length: capacity }, (_, i) => ({
            latitude: d.latitude,
            longitude: d.longitude,
            name: d.name,
            status: i < occupied ? "occupied" : "free",
          }));
          console.log("res", res);
          return res;
        }
        console.log("d", d);
        return {
          latitude: d.latitude,
          longitude: d.longitude,
          name: d.name,
          status:
            d.singleDataPoints.filter((p) => p.valueKey === "status")[0]
              ?.value ?? "unknown",
        };
      });
    console.log("parkingPlaces", parkingPlaces);
    const groups = groupParkingPlacesByLocation(
      parkingPlaces,
      GROUP_DISTNACE_THRESHOLD
    );
    console.log("groups", groups);
    return groups;

    //return data.sort((a, b) => a.latitude - b.latitude);
  }, [data]);

  return (
    <div className="h-full pb-5">
      <Map
        zoom={17}
        keepZoom={true}
        pitch={0}
        padding={PADDING}
        lat={48.67818001339903}
        lng={10.150934979246976}
      >
        {parkingGroups.map((group, i) => {
          const status = groupStatus(group);
          let color: MapMarkerProps["color"] = "green";

          if (status === "some") {
            color = "yellow";
          } else if (status === "occupied") {
            color = "red";
          }
          const activeParkingPlaces = group.parkingPlaces.filter(
            (p) => !!p.status
          );

          const disabledParkingPlaces = activeParkingPlaces.filter((p) =>
            p.name.toLowerCase().includes("behindert")
          );

          const shortParkingPlaces = activeParkingPlaces.filter((p) =>
            p.name.toLowerCase().includes("kurzparker")
          );

          const eParkingPlaces = activeParkingPlaces.filter((p) =>
            p.name.toLowerCase().includes("e-parkplatz")
          );

          const taxiParkingPlaces = activeParkingPlaces.filter((p) =>
            p.name.toLowerCase().includes("taxi")
          );

          const regularParkingPlaces = activeParkingPlaces.filter(
            (p) =>
              !p.name.toLowerCase().includes("behindert") &&
              !p.name.toLowerCase().includes("kurzparker") &&
              !p.name.toLowerCase().includes("e-parkplatz") &&
              !p.name.toLowerCase().includes("taxi")
          );

          if (activeParkingPlaces.length === 0) {
            return null;
          }

          //const interpolated = interpolate(i);
          return (
            <Map.Marker
              scaleWidthZoom
              key={"Group"}
              lat={group.centerLatitude}
              lng={group.centerLongitude}
              options={{
                anchor: "bottom",
              }}
            >
              <MapMarker color={color}>
                {
                  <div className="flex flex-col items-center px-2 py-2.5 pt-1.5 text-xs">
                    <ParkingMarkerItem
                      icon={
                        <ScDbParkenBig
                          {...svgConfig({
                            size: "md",
                            props: {
                              className: "icon-no-colormode",
                            },
                          })}
                        ></ScDbParkenBig>
                      }
                      places={regularParkingPlaces}
                    />
                    <ParkingMarkerItem
                      icon={
                        <ScDbBehindertenparkplatz
                          {...svgConfig({
                            size: "md",
                            props: {
                              className: "icon-no-colormode",
                            },
                          })}
                        />
                      }
                      places={disabledParkingPlaces}
                    />
                    <ParkingMarkerItem
                      icon={
                        <ScDbKurzzeitparkplatz
                          {...svgConfig({
                            size: "md",
                            props: {
                              className: "icon-no-colormode",
                            },
                          })}
                        />
                      }
                      places={shortParkingPlaces}
                    />
                    <ParkingMarkerItem
                      icon={
                        <ScDbElektroparkplatz
                          {...svgConfig({
                            size: "md",
                            props: {
                              className: "icon-no-colormode",
                            },
                          })}
                        />
                      }
                      places={eParkingPlaces}
                    />
                    <ParkingMarkerItem
                      icon={
                        <ScDbTaxiparkplatz
                          {...svgConfig({
                            size: "md",
                            props: {
                              className: "icon-no-colormode",
                            },
                          })}
                        />
                      }
                      places={taxiParkingPlaces}
                    />
                  </div>
                }
              </MapMarker>
            </Map.Marker>
          );
        })}
      </Map>
    </div>
  );
};

const REFETCH_INTERVAL = 1000 * 30;

export const ParkingMapStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();
  const { sorting, setSorting, timeFrame } = useStateboard(REFETCH_INTERVAL);
  const { data, isLoading, error } = useHistoricalDataQuery({
    valueKey: "status,vehicles",
    datetime__gte: timeFrame.start.toISOString(),
    groupIdentifier:
      "c5cf1f80-bfcd-41fd-b874-1bf763a786c1,1199096d-cc09-4d42-b34d-429e45a53310",
  });
  return (
    <StateBoard
      error={!!error}
      loading={isLoading}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title title={t("PARKING_STATEBOARD_TITLE")} />
      <StateBoard.Content>{data && <Content data={data} />}</StateBoard.Content>
      {/* <StateBoard.Select /> */}
    </StateBoard>
  );
};

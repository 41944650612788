import { useEffect, useMemo, useRef, useState } from "react";
import { Options } from "react-select";
import { StateboardSorting } from "../api/types";
import { getTimeFrameBySorting } from "../api/utils";

const ALL = { label: "Gesamte Stadt", value: "all" };

const DEFUALT_INTERVAL = 1000 * 60 * 5;

export const useStateboard = (refetchInterval = DEFUALT_INTERVAL) => {
  const [sorting, setSorting] = useState<StateboardSorting>("day");
  const [location, setLocation] = useState(ALL);
  const [locations, setLocations] = useState([ALL.value]);
  const [timeFrame, setTimeFrame] = useState(getTimeFrameBySorting(sorting));

  useEffect(() => {
    setTimeFrame(getTimeFrameBySorting(sorting));
  }, [sorting]);

  useEffect(() => {
    if (refetchInterval <= 0) return;

    const timeout = setInterval(() => {
      setTimeFrame(getTimeFrameBySorting(sorting));
    }, refetchInterval);

    return () => {
      clearTimeout(timeout);
    };
  }, [refetchInterval, sorting]);

  const locationOptions = useMemo(() => {
    return locations.map((l) => ({
      label: l,
      value: l,
    }));
  }, [locations]);

  return {
    timeFrame,
    sorting,
    setSorting,
    setLocation,
    setLocations,
    locationOptions,
    location,
  };
};

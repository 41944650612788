import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { logout } from "../../../../api/requests";
import { User } from "../../../../api/types";

export interface UserMenuProps {
  user?: User;
}

const hover = "dark:hover:bg-active-dark hover:bg-active-light";
const active = "dark:active:bg-focus-dark active:bg-focus-light";
const bg = "dark:bg-bg-secondary-dark bg-bg-secondary-light";
export const UserMenu = ({ user }: UserMenuProps) => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const onLogOut = async () => {
    await logout();
    client.invalidateQueries(["user"]);
  };
  if (user && user.is_authenticated) {
    return (
      <div
        role="button"
        aria-haspopup="menu"
        aria-controls="usermenu"
        className={`group relative ${bg} h-full flex flex-col`}
        tabIndex={0}
      >
        <div
          className={`h-full ${bg} w-full z-40 px-7 flex flex-col justify-center`}
        >
          <span>
            {user.first_name} {user.last_name}
          </span>
        </div>
        <ul
          id="usermenu"
          role="menu"
          tabIndex={-1}
          className="hidden group-focus-within:block group-hover:block absolute top-full w-full left-0 h-full shadow-lg-light dark:shadow-lg-dark z-20"
        >
          <li className="h-full w-full">
            <button
              className={`${hover} ${active} ${bg} h-full w-full text-left px-7`}
              onClick={onLogOut}
            >
              {t("LOG_OUT")}
            </button>
          </li>
        </ul>
      </div>
    );
  }
  return (
    <Link
      to="/login"
      className={`${hover} ${active} px-7 h-full flex flex-col justify-center`}
    >
      <span>{t("LOG_IN")}</span>
    </Link>
  );
};

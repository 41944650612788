import { NamedFC } from "../../../types/components";

export interface StateBoardTitleProps {
  title: string;
}
export type StateBoardTitleComponentType = NamedFC<StateBoardTitleProps>;

const StateBoardTitle: StateBoardTitleComponentType = ({ title }) => {
  return <h2 className="tracking-[0.48px] font-bold">{title}</h2>;
};
StateBoardTitle.displayName = "StateBoardTitle";

export { StateBoardTitle };

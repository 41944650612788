import React from "react";
export interface ErrorBoundaryProps {
  onError: (error: any, errorInfo: any) => void;
  errorChild: React.ReactNode;
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error: any, errorInfo: any) {
    process.env.NODE_ENV === "development" && console.error(error);
    this.props.onError(error, errorInfo);
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return this.props.errorChild;
    }
    return this.props.children;
  }
}

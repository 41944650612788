import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="uuid-1761ebd6-58a8-4ffc-b305-0c515261c35e"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 13"
    {...props}
  >
    <g id="uuid-f8f52a43-fc7e-4d59-9bf9-a4b2d02f9532">
      <path
        id="uuid-0a8e0a49-7a8a-46bc-bf44-f4ef6635a0c1"
        d="m5.68,11.52L.88,6.71,5.69,1.9"
        fill="none"
        stroke="#94a1b4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
      <line
        id="uuid-abf3dbae-b734-45a8-bde6-509cd0d89b9e"
        x1={1.3}
        y1={6.7}
        x2={11.98}
        y2={6.7}
        fill="none"
        stroke="#94a1b4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbArrowLeft);
export default Memo;

import { SingleDataPoint } from "../../../api/types";

export function sumLastHour(data: SingleDataPoint[]): number {
  const now = Date.now(); // current timestamp
  const oneHourAgo = now - 60 * 60 * 1000; // timestamp one hour ago
  let sum = 0;

  // Iterate through data points in reverse chronological order
  for (let i = 0; i < data.length; i++) {
    const dataPoint = data[i];
    const dateTime = new Date(dataPoint.datetime);
    const timestamp = dateTime.getTime();

    if (timestamp < oneHourAgo) {
      break; // stop iterating if we've gone back more than one hour
    }

    const value = parseFloat(dataPoint.value);
    sum += value;
  }

  return sum;
}

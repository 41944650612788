export type ScColor =
  | "white"
  | "pink"
  | "turquoise"
  | "purple"
  | "green"
  | "bright-green"
  | "yellow"
  | "red"
  | "bad"
  | "orange"
  | "dark"
  | "blue";

export const getBgForColor = (color: ScColor): string => {
  switch (color) {
    case "white":
      return "bg-white";
    case "pink":
      return "bg-pink";
    case "turquoise":
      return "bg-turquoise";
    case "purple":
      return "bg-purple";
    case "green":
      return "bg-dark-green";
    case "bright-green":
      return "bg-bright-green";
    case "yellow":
      return "bg-yellow";
    case "red":
      return "bg-red";
    case "orange":
      return "bg-orange";
    case "dark":
      return "bg-info-light";
    case "blue":
      return "bg-blue";
    case "bad":
      return "bg-bad";
  }
};

export const getTailColor = (color: ScColor): string => {
  switch (color) {
    case "white":
      return "white";
    case "pink":
      return "pink";
    case "turquoise":
      return "turquoise";
    case "purple":
      return "purple";
    case "green":
      return "dark-green";
    case "bright-green":
      return "bright-green";
    case "yellow":
      return "yellow";
    case "red":
      return "red";
    case "orange":
      return "orange";
    case "dark":
      return "info-light";
    case "blue":
      return "blue";
    case "bad":
      return "bad";
  }
};

export const TAILWIND_COLORS: Record<string, string> = {
  ...require("../assets/colors.json"),
};

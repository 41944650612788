import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const DEFAULT_LANGUAGE = "de";
const LANGUAGES = [DEFAULT_LANGUAGE];

const translations: Record<string, any> = LANGUAGES.reduce<Record<string, any>>(
  (acc, lang) => {
    acc[lang] = {
      translations: {
        ...require(`./assets/translations/de/base.json`),
        ...require(`./assets/translations/de/stateboards/weather.json`),
        ...require(`./assets/translations/de/stateboards/traffic.json`),
        ...require(`./assets/translations/de/stateboards/water-level.json`),
        ...require(`./assets/translations/de/stateboards/soil-moisture.json`),
        ...require(`./assets/translations/de/stateboards/bike-box.json`),
        ...require(`./assets/translations/de/stateboards/people-frequency.json`),
        ...require(`./assets/translations/de/stateboards/parking.json`),
        ...require(`./assets/translations/de/stateboards/trashcans.json`),
        ...require(`./assets/translations/de/stateboards/environment.json`),
      },
    };
    return acc;
  },
  {}
);

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  lng: DEFAULT_LANGUAGE,
  resources: translations,
  ns: ["translations"],
  defaultNS: "translations",
});
i18n.languages = LANGUAGES;

export default i18n;

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbErrorFeBe = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g
      id="ad98cc36-7765-4781-8752-c3354b6a6d45"
      data-name="icon"
      fill="#f30b05"
    >
      <path d="M12,2.5a9.5,9.5,0,1,0,9.5,9.5A9.5,9.5,0,0,0,12,2.5Zm4.35,12.44a1,1,0,0,1-.71,1.71,1,1,0,0,1-.7-.3L12,13.41,9,16.35a1,1,0,0,1-.7.3,1,1,0,0,1-.71-1.71L10.57,12,7.63,9.06a1,1,0,0,1,0-1.41A1,1,0,0,1,9,7.65l3,2.94,2.94-2.94a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.41L13.4,12Z" />
    </g>
  </svg>
);

const Memo = memo(SvgScDbErrorFeBe);
export default Memo;

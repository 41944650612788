import React, { ReactElement } from "react";
import { filterChildren } from "../../../util/children";
import { TableListRow } from "./item";

export interface TableListProps {
  className?: string;
  columsNames?: React.ReactNode[];
  children?: React.ReactNode;
}

const TableListComp = ({
  columsNames,
  children,
  className,
}: TableListProps) => {
  const rows = filterChildren(
    children,
    TableListRow.displayName
  ) as ReactElement[];

  return (
    <table className={className ?? "w-full"}>
      <thead className="text-light border-b border-b-inactive-light dark:border-b-inactive-dark">
        {columsNames && (
          <tr>
            {columsNames.map((columnName, i) => (
              <React.Fragment key={`${columnName}_${i}`}>
                {i !== 0 && (
                  <th
                    aria-hidden
                    style={{ width: `${100 / (columsNames.length - 1)}%` }}
                  ></th>
                )}
                <th className="text-left whitespace-nowrap">{columnName}</th>
              </React.Fragment>
            ))}
          </tr>
        )}
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};
const TableList = Object.assign(TableListComp, {
  Row: TableListRow,
});

export { TableList };

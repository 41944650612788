import { forwardRef, memo } from "react";

const classNameForColor = (
  markerColor: "gray" | "green" | "yellow" | "red"
) => {
  let className = "sc-marker shadow-sm-dark ";

  switch (markerColor) {
    case "gray":
      className += "gray";
      break;
    case "green":
      className += "green";
      break;
    case "yellow":
      className += "yellow";
      break;
    case "red":
      className += "red";
      break;
  }
  return className;
};

export interface MapMarkerProps {
  color: "gray" | "green" | "yellow" | "red";
  children?: React.ReactNode;
}

export const MapMarker = memo(
  forwardRef<HTMLDivElement, MapMarkerProps>(({ color, children }, ref) => {
    const className = classNameForColor(color);

    return (
      <div ref={ref} className={className}>
        {children}
      </div>
    );
  })
);

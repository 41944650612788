import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "./polyfill/at";
import "/node_modules/maplibre-gl/dist/maplibre-gl.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import { I18nextProvider } from "react-i18next";
import App from "./App";
import "./chartjs/chartjs-config";
import { ChartColorSubscriber } from "./components/chart-color-subscriber/chart-color-subscriber";
import { ColorModeProvider } from "./context/color-mode";
import i18n from "./i18n-config";
import { DashboardProvider } from "./context/dashboard";
import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";

declare global {
  interface Window {
    settings: Record<string, string>;
  }
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <ColorModeProvider>
        <ChartColorSubscriber />
        <DashboardProvider>
          <App />
        </DashboardProvider>
      </ColorModeProvider>
    </I18nextProvider>
  </QueryClientProvider>
);

import { Header } from "./header";

export interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="flex flex-col h-full ">
      <Header />
      <main className="flex-1 basis-full">{children}</main>
    </div>
  );
};

import React, { ReactElement, useMemo } from "react";
import { NamedFCWithChildren } from "../../../types/components";
import { filterChildren } from "../../../util/children";
import { TableListItemProps, TableListRowItem } from "./value";
interface TableListRowProps {
  className?: string;
}
const TableListRowComp: NamedFCWithChildren<TableListRowProps> = ({
  children,
  className,
}) => {
  const items = filterChildren(
    children,
    TableListRowItem.displayName
  ) as ReactElement<TableListItemProps>[];
  const memoChild = useMemo(() => {
    return items.map((item, i) => {
      return (
        <React.Fragment key={i}>
          {i !== 0 && !item?.props?.noSpacerBefore && (
            <td
              key={"spacer_item" + i}
              aria-hidden
              style={{ width: `${100 / (items.length - 1)}%` }}
            ></td>
          )}
          {item}
        </React.Fragment>
      );
    });
  }, [items]);
  return <tr className={className}>{memoChild}</tr>;
};
TableListRowComp.displayName = "TableListRow";
const TableListRow = Object.assign(TableListRowComp, {
  Item: TableListRowItem,
});

export { TableListRow };

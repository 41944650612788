import {
  Chart as ChartJS,
  LineController,
  Tooltip,
  Interaction,
  PointElement,
} from "chart.js";
import { TAILWIND_COLORS } from "../util/color";

ChartJS.register(LineController, PointElement, Tooltip);

/*
	Plugin for showing a ruler on graph and interpolating betweeen points. 
	We use js here to avoid having to make type declarations but that should defiently be 
	a TODO
*/

ChartJS.defaults.plugins.tooltip.mode = "interpolate";
ChartJS.defaults.datasets.interpolate = true;

// Options for the indicators
const indicatorOptions = {
  radius: 3,
  borderRadius: 0,
  backgroundColor: "transparent",
};
const htmlElem = document.querySelector("html");

const getLabelAndValue = LineController.prototype.getLabelAndValue;

LineController.prototype.getLabelAndValue = function (index) {
  if (index === -1) {
    const meta = this.getMeta();

    const pt: PointElement = meta._pt;

    pt.options.borderColor = meta.dataset?.options.borderColor;
    pt.options.backgroundColor = meta.dataset?.options.borderColor;

    const vScale = meta.vScale;
    const xScale = meta.xScale;

    let label = "";

    if (xScale.type === "time") {
      const dateNum = xScale?.getValueForPixel(pt.x) ?? 0;
      const date = new Date(dateNum);
      if (dateNum !== 0) {
        label = date.toLocaleString(undefined, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    }

    return {
      label,
      value: vScale?.getValueForPixel(pt.y)?.toFixed(0),
    };
  }
  return getLabelAndValue.call(this, index);
};

Interaction.modes.interpolate = function (chart, e, option) {
  const x = e.x;
  const items = [];
  const metas = chart.getSortedVisibleDatasetMetas();
  for (let i = 0; i < metas.length; i++) {
    const meta = metas[i];
    if (meta.type !== "line") continue;
    if (typeof meta.dataset?.interpolate !== "function") continue;
    
    const pt = meta.dataset.interpolate({ x }, "x");
    if (pt) {
      const element = new PointElement({
        ...pt,
        options: { ...indicatorOptions },
      });

      meta._pt = element;
      items.push({ element, index: -1, datasetIndex: meta.index });
    } else {
      meta._pt = null;
    }
  }
  return items;
};

// Plugin to draw the indicators
ChartJS.register({
  id: "indicators",
  afterDraw(chart) {
    const metas = chart.getSortedVisibleDatasetMetas();
    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];

      const pt: PointElement | undefined = meta._pt;

      if (pt) {
        if (i === 0) {
          const scale = meta.yScale;
          if (scale) {
            const isDarkmode = !!htmlElem.classList.contains("dark");
            const context = chart.ctx;
            // render vertical line
            context.beginPath();
            context.strokeStyle = isDarkmode
              ? TAILWIND_COLORS["inactive-dark"]
              : TAILWIND_COLORS["inactive-light"];
            context.moveTo(pt.x, scale.top);
            context.lineTo(pt.x, scale.bottom);
            context.stroke();
          }
        }

        pt.draw(chart.ctx);
      }
    }
  },
  afterEvent(chart, args) {
    if (args.event.type === "mouseout") {
      const metas = chart.getSortedVisibleDatasetMetas();
      for (let i = 0; i < metas.length; i++) {
        metas[i]._pt = null;
      }
      args.changed = true;
    }
  },
});

import { getBgForColor, ScColor } from "../../../util/color";

export interface PointProps {
  color?: ScColor;
  className?: string;
}

export const Point = ({ color = "white", className }: PointProps) => {
  const bgColor = getBgForColor(color);

  return (
    <div
      className={`block rounded-full h-1.5 w-1.5 relative ${bgColor} ${className}`}
    >
      <div
        className={`block rounded-full h-1.5 w-1.5 absolute top-0 left-0 blur-sm ${bgColor}`}
      ></div>
    </div>
  );
};

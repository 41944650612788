import type { DetailedHTMLProps, InputHTMLAttributes } from "react";

export interface TextInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  type?: "text" | "password" | "email";
}

export const TextInput = ({
  children,
  type = "text",
  className: __,
  ...rest
}: TextInputProps) => {
  return (
    <input
      className="p-2.5 bg-white bg-opacity-5 rounded-sm transition-all focus-within:bg-opacity-20 dark:border-active-dark border-active-light border"
      {...rest}
      type={type}
    ></input>
  );
};

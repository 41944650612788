import { ToggleButton } from "./toggle-button";

type ToggleButtonGroupOption<V> = {
  label: string;
  value: V;
};

interface ToggleButtonGroupProps<V> {
  options: ToggleButtonGroupOption<V>[];
  value: V;
  onChange?: (value: V) => void;
}

export const ToggleButtonGroup = <V,>({
  options,
  value,
  onChange
}: ToggleButtonGroupProps<V>) => {


  return (
    <div className="flex flex-row items-center justify-center">
      {options.map((option) => {
        return (
          <ToggleButton
            key={option.label}
            on={value === option.value}
            {...option}
            onClick={onChange}
          ></ToggleButton>
        );
      })}
    </div>
  );
};

import { Reducer } from "react";
import { Layout } from "react-grid-layout";
import { DashboardContextValue } from ".";
import { recalcLayout } from "../../components/grid";
import { GRID_LOCAL_STORAGE_KEY } from "../../constants/local-storage";

export enum DashboardActionTypes {
  UPDATE_LAYOUT = "UPDATE_LAYOUT",
  ADD_LAYOUT = "ADD_LAYOUT",
  SET_COLUMNS = "SET_COLS",
}

interface IDashboardAction<T extends DashboardActionTypes, P = never> {
  type: T;
  payload: P;
}

type UpdateLayoutPayload = {
  cols: number;
  layout: Layout[];
};

// type AddLayoutPayload = {
//   lastCols: number;
//   cols: number;
// };

type SetColumnsPayload = {
  cols: number;
};

export type DashboardAction =
  | IDashboardAction<DashboardActionTypes.UPDATE_LAYOUT, UpdateLayoutPayload>
  | IDashboardAction<DashboardActionTypes.SET_COLUMNS, SetColumnsPayload>;

type DashboardContextReducerState = Omit<DashboardContextValue, "dispatch">;

function saveLayouts(grid: Record<number, Layout[]>) {

  localStorage.setItem(GRID_LOCAL_STORAGE_KEY, JSON.stringify(grid));
}

function setColumnsAction(
  state: DashboardContextReducerState,
  payload: SetColumnsPayload
) {
  if (state.columns === payload.cols) return state;

  if (state.layouts[payload.cols]) {
    return { ...state, columns: payload.cols };
  }
  if (!state.layouts[state.columns]) {
    return { ...state, columns: payload.cols };
  }
  const newLayout = recalcLayout(payload.cols, state.layouts[state.columns], {
    ...state.layouts,
  });
  const resultLayout = {
    ...state.layouts,
    [payload.cols]: newLayout,
  };
  saveLayouts(resultLayout);

  return {
    ...state,
    columns: payload.cols,
    layouts: resultLayout,
  };
}

function updateLayoutAction(
  state: DashboardContextReducerState,
  payload: UpdateLayoutPayload
) {
  const resultLayout = { ...state.layouts, [payload.cols]: payload.layout };
  saveLayouts(resultLayout);
  return {
    ...state,
    layouts: resultLayout,
  };
}

export const reducer: Reducer<
  Omit<DashboardContextValue, "dispatch">,
  DashboardAction
> = (state, action: DashboardAction) => {
  switch (action.type) {
    case DashboardActionTypes.UPDATE_LAYOUT:
      return updateLayoutAction(state, action.payload);
    case DashboardActionTypes.SET_COLUMNS:
      return setColumnsAction(state, action.payload);
  }
};

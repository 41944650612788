import React from "react";
import { HistoricalData } from "../api/types";

import { LayoutDefinition } from "../components/grid/item";

export enum StateboardType {
  BIKEBOX = "bikebox",
  PARKING_MAP = "parking-map",
  PARKING_SUMMARY = "parking-summary",
  PERSON_FREQUENCY = "person-frequency",
  SOIL_MOISTURE = "soil-moisture",
  TRAFFIC = "traffic",
  WATER_LEVEL = "water-level",
  WEATHER = "weather",
  TRASHCAN = "trashcan",
  VISITORCHART = "visitor-chart",
  ENVIRONMENT = "ENVIRONMENT",
  PERSON_FREQUENCY_DETAIL = "person-frequency-detail",
}

export enum Sentiment {
  GOOD,
  BAD,
  NEUTRAL,
}

export interface StateboardProps {
  id: string;
}

export type StateboardComponent = React.ComponentType<StateboardProps>;

export interface StatebaordContentProps {
  data: HistoricalData[];
}

export type StateboardContentComponent<P = {}> = React.ComponentType<
  StatebaordContentProps & P
>;

export interface Stateboard {
  Component: StateboardComponent;
  layoutDefinition: LayoutDefinition;
}

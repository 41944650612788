import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import GridLayoutComponent, { WidthProvider } from "react-grid-layout";
import {
  DRAGGABLE_CANCEL_CLASS,
  DRAGGABLE_HANDLE_CLASS,
  ROW_HEIGHT,
} from "../../constants/grid";
import { DashboardContext } from "../../context/dashboard";
import { DashboardActionTypes } from "../../context/dashboard/reducer";
import { NamedFCWithChildren } from "../../types/components";
import { filterChildren } from "../../util/children";
import { GridItem, GridItemComponentType, GridItemProps } from "./item";
//@ts-ignore

const GridLayout = WidthProvider(GridLayoutComponent);
//TODO: Move to dashboard context useReducer and make it cleaner
export interface GridContextProviderProps {
  children?: React.ReactNode;
}
const MOBILE_BREAKPOINT = 430;
const STATEBOARD_MIN_WIDTH = 380;

function getCombinedHeight(layout: GridLayoutComponent.Layout[]) {
  return layout.reduce((acc, cur) => acc + cur.h, 0);
}
function findLowestColIndex(byColumn: GridLayoutComponent.Layout[][]) {
  let lowest = Infinity;
  let lowestIndex = -1;
  byColumn.forEach((c, i) => {
    const h = getCombinedHeight(c);
    if (h < lowest) {
      lowestIndex = i;
      lowest = h;
    }
  });
  return lowestIndex;
}
function getByCol(layout: GridLayoutComponent.Layout[], cols: number) {
  const byColumn: GridLayoutComponent.Layout[][] = [];
  for (let i = 0; i < cols; i += 1) {
    byColumn.push(layout.filter((l) => l.x === i));
  }
  return byColumn;
}
// This algorithm is pretty slow
// Someday someone smart enough is going to do this properly
// Before someone changes it though they should add some testing
export function recalcLayout(
  cols: number,
  layout: GridLayoutComponent.Layout[],
  layouts: Record<number, GridLayoutComponent.Layout[]>
) {
  if (cols in layouts) {
    return layouts[cols];
  }

  const result: GridLayoutComponent.Layout[] = [];

  layout.forEach((l) => {
    if (l.x >= cols) {
      result.push({ ...l, x: cols - 1 });
    } else result.push(l);
  });

  // if we generate a new layout we want to ensure that roughly the same amount of stateboards are on each column
  const combinedHeight = getCombinedHeight(layout);
  const desiredHeightPerCol = Math.floor(combinedHeight / cols);
  const byColumn = getByCol(layout, cols);

  [...byColumn].forEach((lCol, cI) => {
    let heightAcc = 0;
    lCol.forEach((l) => {
      // Move over to the col with the least height
      if (heightAcc > desiredHeightPerCol) {
        const lowestIndex = findLowestColIndex(getByCol(layout, cols));

        // check if the next col is would
        l.x = lowestIndex;
      }
      heightAcc += l.h;
      result.push(l);
    });
  });

  return result as GridLayoutComponent.Layout[];
}
function getCols(width: number, margin: number) {
  const calcWidth = width - margin;
  const calcCols = Math.floor(calcWidth / (STATEBOARD_MIN_WIDTH + margin * 2));
  return Math.max(calcCols, 1);
}

export interface GridComponent extends NamedFCWithChildren {
  Item: GridItemComponentType;
}

export const Grid: GridComponent = ({ children }) => {
  const gridItems = filterChildren(
    children,
    GridItem.displayName
  ) as ReactElement<GridItemProps>[];
  const { layouts, columns: cols, dispatch } = useContext(DashboardContext);
  const [width, setWidth] = useState(window.innerWidth);

  const margin = useMemo(() => (width <= MOBILE_BREAKPOINT ? 20 : 30), [width]);

  const layout: GridLayoutComponent.Layout[] | undefined = layouts[cols];
  const gridRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch({
      type: DashboardActionTypes.SET_COLUMNS,
      payload: {
        cols: getCols(width, margin),
      },
    });
  }, [width, margin, dispatch]);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onLayoutChange = useCallback(
    (layout: GridLayoutComponent.Layout[]) => {
      dispatch({
        type: DashboardActionTypes.UPDATE_LAYOUT,
        payload: { layout: layout, cols },
      });
    },
    [cols, dispatch]
  );

  return (
    <div ref={gridRef}>
      <GridLayout
        margin={[margin, margin]}
        rowHeight={ROW_HEIGHT}
        cols={cols}
        draggableCancel={`.${DRAGGABLE_CANCEL_CLASS}`}
        draggableHandle={`.${DRAGGABLE_HANDLE_CLASS}`}
        compactType={"vertical"}
        layout={layout}
        isDraggable={cols > 1}
        onLayoutChange={onLayoutChange}
      >
        {gridItems}
      </GridLayout>
    </div>
  );
};
Grid.displayName = "Grid";
Grid.Item = GridItem;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStateboard } from "../../../hooks/useStateboard";
import { FillBar } from "../../util/fill-bar";
import { TableList } from "../../util/table-list";
import { TrendIcon } from "../../util/trend";
import { StateBoard } from "../base";
import { Sentiment, StateboardComponent } from "../../../types/stateboard";
import { HistoricalData, StateboardSorting } from "../../../api/types";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { numberToDotString } from "../../../util/number-strings";
import { GridItemSize } from "../../grid/item";
import {
  ScDbGarbageGreen,
  ScDbGarbageRed,
  ScDbGarbageYellow,
} from "../../icons";
import { svgConfig } from "../../../constants/svg-size";
import { throwOnNoData } from "../../../util/throw-no-data";

const TRASH_ICONS = {
  [Sentiment.BAD]: ScDbGarbageRed,
  [Sentiment.NEUTRAL]: ScDbGarbageYellow,
  [Sentiment.GOOD]: ScDbGarbageGreen,
};

const Content = ({ data }: { data: HistoricalData[] }) => {
  const { t } = useTranslation();

  throwOnNoData(data);

  const TRASH_TEXT = {
    [Sentiment.GOOD]: t("TRASHCAN_EMPTY"),
    [Sentiment.NEUTRAL]: t("TRASHCAN_LITTLE_FILLED"),
    [Sentiment.BAD]: t("TRASHCAN_FILLED"),
  };

  const garbageCans = useMemo(() => {
    return data;
  }, [data]);

  return (
    <div className="flex flex-col h-full w-full pb-4 ">
      <div className="h-full overflow-y-scroll grow-0 ">
        <TableList className="text-xs mx-2 ">
          {garbageCans
            .sort(
              (a, b) =>
                Number(b.singleDataPoints[0]?.value) -
                Number(a.singleDataPoints[0]?.value)
            )
            .map((garbage) => {
              if (garbage.singleDataPoints.length <= 0) return null;

              let sentiment = Sentiment.GOOD;
              let curValue = Number(garbage.singleDataPoints[0].value);

              if (curValue > 70) {
                sentiment = Sentiment.NEUTRAL;
              }
              if (curValue > 80) {
                sentiment = Sentiment.BAD;
              }
              const Icon = TRASH_ICONS[sentiment];

              const padding = "pb-2 pr-3";
              return (
                <TableList.Row
                  key={garbage.id}
                  className="border-b dark:border-inactive-dark border-inactive-light"
                >
                  <TableList.Row.Item className={padding}>
                    <div className="flex flex-row items-center ">
                      <Icon {...svgConfig({ size: "xl" })} />
                      <span className="bloc ml-1 whitespace-nowrap  text-xs">
                        {garbage.name}
                      </span>
                    </div>
                  </TableList.Row.Item>
                  <TableList.Row.Item className={padding}>
                    <div className={`text-right justify-between items-center`}>
                      <span className={`whitespace-nowrap font-bold`}>
                        {TRASH_TEXT[sentiment]}
                      </span>
                    </div>
                  </TableList.Row.Item>
                </TableList.Row>
              );
            })}
        </TableList>
      </div>
    </div>
  );
};

export const TrashCanStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();
  const { sorting, setSorting, timeFrame } = useStateboard();
  const { isLoading, data, isError } = useHistoricalDataQuery({
    datetime__gte: timeFrame.start.toISOString(),
    groupIdentifier: "3d04bfd6-5f14-45bf-bc31-98deaef5ea54",
    valueKey: "filling",
  });

  return (
    <StateBoard
      sorting={sorting}
      loading={isLoading}
      error={isError}
      onSortingChange={setSorting}
      size={GridItemSize.SMALL}
    >
      <StateBoard.Title title={t("TRASHCAN_STATEBOARD_NAME")} />
      <StateBoard.Content>{data && <Content data={data} />}</StateBoard.Content>
      {/* <StateBoard.Select /> */}
    </StateBoard>
  );
};

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbAuto = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="f155033e-5755-4e88-bb08-abc7e0a848c1" data-name="icon">
      <path
        d="M3.9,11.72V17.9A1.08,1.08,0,0,0,5,19H6.06A1.08,1.08,0,0,0,7.14,17.9V16.82a1.08,1.08,0,0,1,1.08-1.08h7.56a1.08,1.08,0,0,1,1.08,1.08V17.9A1.08,1.08,0,0,0,17.94,19H19A1.08,1.08,0,0,0,20.1,17.9V11.7a1.18,1.18,0,0,0-.13-.52L17.41,5.57A1.08,1.08,0,0,0,16.47,5H7.8a1,1,0,0,0-.91.52L4.06,11.16A1,1,0,0,0,3.9,11.72Z"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <line
        x1={10.2}
        y1={13.16}
        x2={13.98}
        y2={13.16}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={7.14}
        cy={13.08}
        r={1.08}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <circle
        cx={16.86}
        cy={13.08}
        r={1.08}
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <polygon
        points="17.04 9.96 7.14 9.96 8.43 6.98 15.78 6.98 17.04 9.96"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbAuto);
export default Memo;

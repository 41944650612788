import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect, { GroupBase, StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import { useColorMode } from "../../../hooks/useColorMode";
import { NamedFC } from "../../../types/components";

export interface SelectComponentType extends NamedFC<StateManagerProps> {}

const Select: SelectComponentType = <
  Option,
  Multi extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: StateManagerProps<Option, Multi, Group>
) => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  const styles = React.useMemo<StylesConfig<Option, Multi, Group>>(() => {
    const color = colorMode === "dark" ? "#fff" : "#5F6162";
    const colorLight = colorMode === "dark" ? "#94A1B4" : "#A7A8A8";
    const activeColor = colorMode === "dark" ? "#fff" : "#5F6162";
    const clickBg = colorMode === "dark" ? "#94A1B4" : "#A3A4A5";
    const fontSize = "0.875rem";
    const boxShadow =
      colorMode === "dark"
        ? "0px 3px 10px #0000001A"
        : "0px 3px 10px #0000001A;";
    return {
      indicatorSeparator(base, state) {
        return {
          display: "none",
        };
      },
      option(base, state) {
        if (state.isSelected)
          return {
            display: "none",
          };
        return {
          ...base,
          transition: "color 0.2s ease-in-out, background 0.2s ease-in-out",
          color: state.isFocused ? activeColor : colorLight,
          background: "none",
          fontSize,
          ":active": {
            background: clickBg,
          },
        };
      },
      indicatorsContainer(base, state) {
        return {
          display: "none",
        };
      },
      noOptionsMessage(base, state) {
        return {
          ...base,
          fontSize,
        };
      },
      valueContainer(base, state) {
        return {
          ...base,
          paddingTop: "0",
          paddingBottom: "0",
        };
      },
      control(base, state) {
        return {
          ...base,
          boxShadow,
          color,
          background: colorMode === "dark" ? "#697280" : "#D2D2D3",
          transition: "none",
          border: "none",
          borderRadius: "12px",
          minHeight: 24,
        };
      },
      menuList(base, state) {
        return {
          ...base,
          padding: 0,
        };
      },
      menu(base, state) {
        return {
          ...base,
          boxShadow,
          background: colorMode === "dark" ? "#4B515A" : "#E7E8E8",
          border: "0",
          overflow: "hidden",
          borderRadius: "12px",
          padding: "0",
          marginTop: "10px",
          marginBottom: "10px",
        };
      },
      input(base, state) {
        return {
          ...base,
          margin: "0",
          color,
          fontSize,
        };
      },
      singleValue(base, state) {
        return {
          ...base,
          color,
          fontSize,
        };
      },
      placeholder(base, state) {
        return {
          ...base,
          fontSize,
          color: colorLight,
        };
      },
    };
  }, [colorMode]);
  const mergedProps = useMemo(() => {
    return {
      placeholder: t("SELECT_PLACEHOLDER"),
      noOptionsMessage: () => t("SELECT_NO_OPTIONS_MESSAGE"),
      ...props,
      styles,
    };
  }, [props, styles, t]);

  return <ReactSelect {...mergedProps} />;
};
Select.displayName = "Select";

export { Select };

import { access } from "fs";
import { createContext, Dispatch, useReducer } from "react";
import { Layout } from "react-grid-layout";
import {
  GRID_CHECKSUM_KEY,
  GRID_LOCAL_STORAGE_KEY,
} from "../../constants/local-storage";
import { STATE_BOARDS } from "../../constants/stateboards";
import { StateboardType } from "../../types/stateboard";
import { DashboardAction, reducer } from "./reducer";

export interface StaticContextStateboard {
  id: string;
  tyoe: StateboardType;
  position: { x: number; y: number };
}

export type ContextStateboard = StaticContextStateboard;

export interface DashboardContextValue {
  stateboards: Record<string, ContextStateboard>;
  columns: number;
  layouts: Record<number, Layout[]>;
  dispatch: Dispatch<DashboardAction>;
}

let initalStateboards = Object.entries(StateboardType).map(([, key], i) => {
  return {
    id: i,
    tyoe: key,
    position: { x: i - Math.floor(i / 4) * 4, y: 0 },
  };
});

function getInitalLayouts(): Record<number, Layout[]> | null {
  const item = localStorage.getItem(GRID_LOCAL_STORAGE_KEY);
  if (!item) return null;
  return JSON.parse(item);
}
//TODO: in future we might want to add a version to the grid
//TODO: remove once stateboards are editable
function validateInitalLayouts(layout: Record<number, Layout[]>): boolean {
  const curChecksum = window.btoa(
    JSON.stringify(Object.values(STATE_BOARDS).map((sb) => sb.layoutDefinition))
  );
  const savedChecksum = localStorage.getItem(GRID_CHECKSUM_KEY);

  if (curChecksum !== savedChecksum) {
    localStorage.setItem(GRID_CHECKSUM_KEY, curChecksum);
    return false;
  }
  return true;
}
const initalValue: DashboardContextValue = {
  stateboards: initalStateboards.reduce((acc, curr) => {
    return { ...acc, [curr.id]: curr };
  }, {}),
  layouts:
    //TODO: remove once stateboards are editable
    getInitalLayouts() && validateInitalLayouts(getInitalLayouts()!)
      ? getInitalLayouts()!
      : {},
  columns: 4,
  dispatch: () => {},
};

export const DashboardContext =
  createContext<DashboardContextValue>(initalValue);

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initalValue);
  const context = {
    ...state,
    dispatch,
  };
  return (
    <DashboardContext.Provider value={context}>
      {children}
    </DashboardContext.Provider>
  );
};

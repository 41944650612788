import { useTranslation } from "react-i18next";
import { useColorMode } from "../../../hooks/useColorMode";
import { GridItemSize } from "../../grid/item";
import {
  SimplePointList,
  SimplePointListItem,
} from "../../util/simple-point-list";
import { Weather, WeatherDescription } from "../../content/weather";
import { StateBoard } from "../base";
import { StateboardComponent } from "../../../types/stateboard";
import { StateboardContent } from "../base/types";
import { useStateboard } from "../../../hooks/useStateboard";
import { useEffect, useMemo } from "react";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { throwOnNoData } from "../../../util/throw-no-data";

const Content: StateboardContent = ({ data }) => {
  const { t } = useTranslation();

  throwOnNoData(data);

  const { colorMode } = useColorMode();

  const filtered = useMemo(() => {
    const all = data[0].singleDataPoints;
    const windSpeed = all.filter((n) => n.valueKey === "wind.speed");
    const rain = all.filter((n) => n.valueKey === "rain");
    const relativeHumidity = all.filter((n) => n.valueKey === "humidity");
    const temperature = all.filter((n) => n.valueKey === "temperature");
    const status = all.filter((n) => n.valueKey === "state")[0].value;
    return {
      status,
      windSpeed,
      rain,
      relativeHumidity,
      temperature,
    };
  }, [data]);

  const points: SimplePointListItem[] = useMemo(() => {
    return [
      {
        label: t("WEATHER_STATEBOARD_HUMIDITY"),
        value: filtered.relativeHumidity[0]!.value + "%",
        color: colorMode === "dark" ? "white" : "dark",
      },
      {
        label: t("WEATHER_STATEBOARD_RAIN"),
        value: filtered.rain[0]!.value + " l/m2",
        color: colorMode === "dark" ? "white" : "dark",
      },
      {
        label: t("WEATHER_STATEBOARD_WIND"),
        value: filtered.windSpeed[0]!.value + " m/s",
        color: "yellow",
      },
    ];
  }, [
    colorMode,
    filtered.rain,
    filtered.relativeHumidity,
    filtered.windSpeed,
    t,
  ]);

  const weather = useMemo<WeatherDescription>(() => {
    switch (filtered.status) {
      case "dry":
      case "sunny":
        return WeatherDescription.SUNNY;
      case "sunny - small clouds":
      case "sunny - clouds":
      case "sunny - fog":
        return WeatherDescription.SUNNY_CLOUDY;
      case "clouds":
      case "fog":
        return WeatherDescription.CLOUDY;
      case "rain":
      case "drizzle":
      case "sunny - clouds - rain":
        return WeatherDescription.RAIN;
      case "snowrain":
      case "snow":
      case "sunny - clouds - snow":
      case "sunny - clouds - snowrain":
        return WeatherDescription.SNOW;
      case "thunderstorm":
        return WeatherDescription.STORM;
      default:
        return WeatherDescription.CLOUDY;
    }
  }, [filtered.status]);

  return (
    <div className="flex flex-row items-center justify-between ">
      <Weather
        description={weather}
        temperature={filtered.temperature[0]!.value}
      />
      <div className="ml-2 text-xs">
        {points.map((p) => {
          return (
            <div
              key={p.label}
              className="flex mb-2 flex-row justify-between  whitespace-nowrap"
            >
              <span className="text-light">{p.label}</span>
              <span className="ml-10 text-right">{p.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const REFETCH_INTERVAL = 1000 * 60 * 10;

export const WeatherStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();
  const { timeFrame, sorting, setSorting } = useStateboard(REFETCH_INTERVAL);

  const { isLoading, error, data } = useHistoricalDataQuery({
    labelIdentifier: "10517",
    datetime__gte: timeFrame.start.toISOString(),
    groupIdentifier: "d005a790-eded-4cde-a090-06a55c3874d5",
  });

  return (
    <StateBoard
      size={GridItemSize.SMALL}
      loading={isLoading}
      error={!!error}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title
        title={t("WEATHER_STATEBOARD_TITLE")}
      ></StateBoard.Title>
      <StateBoard.Content>{data && <Content data={data} />}</StateBoard.Content>
    </StateBoard>
  );
};

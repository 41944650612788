import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbSnowColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="e81db5a6-a1db-4efb-afbb-bef2fa97f2d8"
        cx={12}
        cy={12.12}
        r={6.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.6} />
        <stop offset={0.2} stopColor="#fff" stopOpacity={0.5} />
        <stop offset={0.61} stopColor="#fff" stopOpacity={0.26} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </radialGradient>
    </defs>
    <g id="a1245cf9-76b1-466c-8a50-27c9b69c6866" data-name="color">
      <circle
        cx={12}
        cy={12.12}
        r={6.01}
        fill="url(#e81db5a6-a1db-4efb-afbb-bef2fa97f2d8)"
      />
    </g>
    <g id="faa19c37-8522-409b-bdea-554b7adb63df" data-name="icon">
      <line
        x1={12}
        y1={3.48}
        x2={12}
        y2={20.52}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <polyline
        points="14.5 4 12 6.5 9.5 4"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="9.5 20 12 17.5 14.5 20"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={19.36}
        y1={16.28}
        x2={4.64}
        y2={7.72}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <polyline
        points="17.66 18.18 16.75 14.77 20.17 13.86"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="6.34 5.82 7.25 9.23 3.83 10.14"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={4.62}
        y1={16.28}
        x2={19.38}
        y2={7.76}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <polyline
        points="3.82 13.86 7.24 14.77 6.33 18.19"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="20.18 10.18 16.76 9.27 17.67 5.85"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbSnowColor);
export default Memo;

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbGewitterColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="b1892723-95e7-4bda-b593-a4c2b48f04bd"
        cx={12}
        cy={8.92}
        r={6.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#a0cbcc" stopOpacity={0.8} />
        <stop offset={0.16} stopColor="#a0cbcc" stopOpacity={0.78} />
        <stop offset={0.31} stopColor="#a0cbcc" stopOpacity={0.72} />
        <stop offset={0.47} stopColor="#a0cbcc" stopOpacity={0.62} />
        <stop offset={0.63} stopColor="#a0cbcc" stopOpacity={0.49} />
        <stop offset={0.78} stopColor="#a0cbcc" stopOpacity={0.31} />
        <stop offset={0.94} stopColor="#a0cbcc" stopOpacity={0.09} />
        <stop offset={1} stopColor="#a0cbcc" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="a25334a0-ef52-4979-9d5a-1deb8e6f1e3b"
        cx={1.27}
        cy={-6.11}
        r={2.75}
        gradientTransform="matrix(0.03, -1.24, 1.68, 0.01, 21.88, 16.87)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f4c914" stopOpacity={0.8} />
        <stop offset={0.1} stopColor="#f4c914" stopOpacity={0.73} />
        <stop offset={0.3} stopColor="#f4c914" stopOpacity={0.55} />
        <stop offset={0.57} stopColor="#f4c914" stopOpacity={0.26} />
        <stop offset={0.8} stopColor="#f4c914" stopOpacity={0} />
      </radialGradient>
    </defs>
    <g id="ba20e5bb-f071-426a-ba90-694a919ae174" data-name="color">
      <path
        d="M16.72,7.11A3.61,3.61,0,0,0,9.59,7H8.05a3.42,3.42,0,1,0,0,6.83H16a3.41,3.41,0,0,0,.77-6.73Z"
        fill="url(#b1892723-95e7-4bda-b593-a4c2b48f04bd)"
      />
      <rect
        x={9.59}
        y={9.92}
        width={4.1}
        height={10.61}
        transform="translate(10.83 -3.92) rotate(34.97)"
        fill="url(#a25334a0-ef52-4979-9d5a-1deb8e6f1e3b)"
      />
    </g>
    <g id="bc2c83a9-40ec-4620-a09b-374f3c2bb199" data-name="icon">
      <polyline
        points="13.18 11.12 10.17 15.34 13.54 15.3 10.74 19.32"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.66,13.58H16a3.41,3.41,0,0,0,.77-6.73,3.61,3.61,0,0,0-7.13-.1H8.05a3.42,3.42,0,1,0,0,6.83"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={7.54}
        y1={16.31}
        x2={6.28}
        y2={18.18}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={8.92}
        y1={18.39}
        x2={7.66}
        y2={20.26}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={14.95}
        y1={17.13}
        x2={13.7}
        y2={19}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbGewitterColor);
export default Memo;

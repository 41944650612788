import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbBewoelktColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="f230ee1b-07be-4e83-8366-e3d04c912d95"
        cx={8.98}
        cy={11.15}
        r={3.28}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f4c914" stopOpacity={0.8} />
        <stop offset={0.12} stopColor="#f4c914" stopOpacity={0.73} />
        <stop offset={0.37} stopColor="#f4c914" stopOpacity={0.55} />
        <stop offset={0.71} stopColor="#f4c914" stopOpacity={0.26} />
        <stop offset={1} stopColor="#f4c914" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="abb98e92-de22-435a-9b3e-41be496b0bea"
        cx={14.48}
        cy={14.62}
        r={6.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#a0cbcc" stopOpacity={0.8} />
        <stop offset={0.16} stopColor="#a0cbcc" stopOpacity={0.78} />
        <stop offset={0.31} stopColor="#a0cbcc" stopOpacity={0.72} />
        <stop offset={0.47} stopColor="#a0cbcc" stopOpacity={0.62} />
        <stop offset={0.63} stopColor="#a0cbcc" stopOpacity={0.49} />
        <stop offset={0.78} stopColor="#a0cbcc" stopOpacity={0.31} />
        <stop offset={0.94} stopColor="#a0cbcc" stopOpacity={0.09} />
        <stop offset={1} stopColor="#a0cbcc" stopOpacity={0} />
      </radialGradient>
    </defs>
    <g id="fe0edd31-c238-4595-b4bd-95d9e089e7d9" data-name="color">
      <circle
        cx={8.98}
        cy={11.15}
        r={3.28}
        fill="url(#f230ee1b-07be-4e83-8366-e3d04c912d95)"
      />
      <path
        d="M19.2,12.81a3.61,3.61,0,0,0-7.13-.1H10.53a3.42,3.42,0,0,0,0,6.83h7.9a3.41,3.41,0,0,0,.77-6.73Z"
        fill="url(#abb98e92-de22-435a-9b3e-41be496b0bea)"
      />
    </g>
    <g id="e4803952-3df8-4e25-aa8a-0113fe85a7f6" data-name="icon">
      <path
        d="M7.21,14.3A3.43,3.43,0,0,1,6.37,12a3.5,3.5,0,0,1,6.54-1.73"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={2.3}
        y1={12.18}
        x2={4.07}
        y2={12.18}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={4.41}
        y1={6.79}
        x2={5.66}
        y2={8.04}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={9.71}
        y1={4.46}
        x2={9.71}
        y2={6.23}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={15.11}
        y1={6.57}
        x2={13.86}
        y2={7.82}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.06,12.81a3.61,3.61,0,0,0-7.14-.1H10.38a3.42,3.42,0,0,0,0,6.83h7.91a3.41,3.41,0,0,0,.77-6.73Z"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbBewoelktColor);
export default Memo;

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbBus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="a53654b6-ec17-4535-8820-9078b189f650" data-name="icon">
      <path
        d="M6.28,3.47H17.7a1,1,0,0,1,1,1v13.3a0,0,0,0,1,0,0H5.28a0,0,0,0,1,0,0V4.47A1,1,0,0,1,6.28,3.47Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5,17.8h2a.25.25,0,0,1,.25.25v1.24A1.25,1.25,0,0,1,7.5,20.53h0a1.25,1.25,0,0,1-1.25-1.25V18.05A.25.25,0,0,1,6.5,17.8Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.54,17.8h2a.25.25,0,0,1,.25.25v1.24a1.25,1.25,0,0,1-1.25,1.25h0a1.25,1.25,0,0,1-1.25-1.25V18.05A.25.25,0,0,1,15.54,17.8Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={5.27}
        y={5.52}
        width={13.43}
        height={6.55}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={7.99}
        cy={14.79}
        r={1.1}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={16.06}
        cy={14.79}
        r={1.1}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.1,10.61H4a.5.5,0,0,1-.5-.5V7.91A.5.5,0,0,1,4,7.42H5.1"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9,7.42H20a.5.5,0,0,1,.51.5v2.19a.5.5,0,0,1-.5.5H18.9"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={10.42}
        y1={15.74}
        x2={13.69}
        y2={15.74}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbBus);
export default Memo;

//see: https://stackoverflow.com/questions/52205399/percent-pie-chart-with-css-only/52205730#52205730
import "./style.css";

import { CSSProperties, memo } from "react";
import { TAILWIND_COLORS } from "../../../util/color";
import { useColorMode } from "../../../hooks/useColorMode";
import { Point } from "../point";

interface PieProps {
  thickness?: number;
  size?: number;
  fill: number;
  fillColor: string;
  emptyColor: string;
  className?: string;
}

const Pie = ({
  thickness = 3,
  size = 70,
  fillColor,
  emptyColor,
  fill,
  className,
}: PieProps) => {
  const circleStyle = {
    "--p": fill,
    "--w": `${size}px`,
    "--c": fillColor,
    "--b": `${thickness}px`,
  } as CSSProperties;

  const outerStyle = {
    "--p": 100,
    "--w": `${size}px`,
    "--c": emptyColor,
    "--b": `${thickness}px`,
  } as CSSProperties;

  return (
    <div
      className={`relative ${className ?? ""}`}
      style={{ height: size, width: size }}
    >
      <div
        className="pie top-0 no-round !absolute inset-0"
        style={outerStyle}
      ></div>
      <div
        className="pie top-0 no-round animate !absolute inset-0"
        style={circleStyle}
      ></div>
    </div>
  );
};

export type FillCircleItem = {
  value: string | number;
  label: string;
  percantage: number;
  color?: string;
};

export interface FillCircleProps {
  items: [FillCircleItem, FillCircleItem];
  // Size in pixels
  size?: "big" | "small";
}

export const FillCircle = memo(
  ({ items: [item1, item2], size = "big" }: FillCircleProps) => {
    //TODO: calculate these values and dont make them static
    const { isDark } = useColorMode();

    const circleSize = size === "big" ? 134 : 105;
    const innterSize = size === "big" ? 107.5 : 86;

    const pieOuterSize = size === "big" ? 126 : 105;
    const pieInnerSize = size === "big" ? 110 : 90.5;

    const textSmall = size === "big" ? "text-xs" : "text-xxs";
    const textLarge = size === "big" ? "text-lg" : "text-xss";

    return (
      <div
        className={`relative inline-block ${size === "big" ? "p-2" : "p-0"}`}
        style={{ height: circleSize, width: circleSize }}
      >
        <div className="dark:bg-inactive-dark bg-inactive-light absolute rounded-full inset-0 h-full w-full  darK:shadow-sm-dark shadow-sm-light"></div>
        <div
          className={`dark:bg-bg-secondary-dark bg-inactive-light absolute text-xss ${
            size === "big" ? "inset-3.5" : "inset-2.5"
          } rounded-full p-2`}
          style={{ width: innterSize, height: innterSize }}
        >
          <div
            className={`flex flex-col text-center justify-around items-center pt-0.5`}
          >
            <div className="flex flex-col items-center ">
              <div className="flex flex-row  items-center -ml-1">
                <Point color={"pink"} />
                <span
                  className={`${textLarge} block font-bold ml-1.5 text-xss`}
                >
                  {item1.value}
                </span>
              </div>
              <span className={`${textSmall} block text-light text-[8px]`}>
                {item1.label}
              </span>
            </div>
            <span className="block w-4/5 dark:bg-inactive-dark bg-bg-secondary-light h-[1px] my-0.5 rounded-lg"></span>
            <div className="flex flex-col items-center">
              <div className="flex flex-row items-center -ml-1">
                <Point color={"turquoise"} />
                <span
                  className={`${textSmall} block font-bold ml-1.5 text-xss`}
                >
                  {item2.value}
                </span>
              </div>

              <span className={`${textSmall} block text-light text-[8px]`}>
                {item2.label}
              </span>
            </div>
          </div>
        </div>
        <Pie
          className={`!absolute ${size === "big" ? "inset-1" : "inset-0"}`}
          fillColor={
            item1.color ?? (isDark ? TAILWIND_COLORS["white"] : "#000000")
          }
          emptyColor={
            isDark
              ? TAILWIND_COLORS["active-dark"]
              : TAILWIND_COLORS["active-light"]
          }
          fill={item1.percantage}
          thickness={2}
          size={pieOuterSize}
        />
        <Pie
          className={`!absolute ${
            size === "big" ? "inset-3" : "inset-[7.5px]"
          }`}
          fillColor={
            item2.color ?? (isDark ? TAILWIND_COLORS["white"] : "#000000")
          }
          emptyColor={
            isDark
              ? TAILWIND_COLORS["active-dark"]
              : TAILWIND_COLORS["active-light"]
          }
          fill={item2.percantage}
          thickness={2}
          size={pieInnerSize}
        />
      </div>
    );
  }
);

export interface FillCircleSingleProps {
  item: FillCircleItem;
  // Size in pixels
  size?: "big" | "small";
}

export const FillCircleSingle = memo(
  ({ item, size = "big" }: FillCircleSingleProps) => {
    //TODO: calculate these values and dont make them static

    const { isDark } = useColorMode();

    const circleSize = size === "big" ? 134 : 105;
    const innterSize = size === "big" ? 107.5 : 86;

    const pieOuterSize = size === "big" ? 126 : 105;

    const textSmall = size === "big" ? "text-md" : "text-xs";
    const textLarge = size === "big" ? "text-lg" : "text-xs";

    return (
      <div
        className={`relative inline-block ${size === "big" ? "p-2" : "p-0"}`}
        style={{ height: circleSize, width: circleSize }}
      >
        <div className="dark:bg-inactive-dark bg-inactive-light absolute rounded-full inset-0 h-full w-full darK:shadow-sm-dark shadow-sm-light"></div>
        <div
          className={`dark:bg-bg-secondary-dark bg-bg-secondary-light absolute text-xss ${
            size === "big" ? "inset-3.5" : "inset-2.5"
          } rounded-full p-2`}
          style={{ width: innterSize, height: innterSize }}
        >
          <div
            className={`flex flex-col h-full text-center justify-around items-center pt-0.5`}
          >
            <div className="flex flex-col">
              <span className={`${textLarge} block font-bold`}>
                {item.value}
              </span>
              <span className={`${textSmall} block text-light`}>
                {item.label}
              </span>
            </div>
          </div>
        </div>
        <Pie
          className={`!absolute ${size === "big" ? "inset-1" : "inset-0"}`}
          fillColor={isDark ? TAILWIND_COLORS["white"] : "#000000"}
          emptyColor={
            isDark
              ? TAILWIND_COLORS["active-dark"]
              : TAILWIND_COLORS["active-light"]
          }
          fill={item.percantage}
          thickness={2}
          size={pieOuterSize}
        />
      </div>
    );
  }
);

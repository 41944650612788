import { ScColor } from "../../../util/color";
import { StatusBar } from "../../content/status-bar";

export interface HighlightValueProps {
  value: string;
  color?: ScColor;
  label: React.ReactNode;
}

export const HighlightValue = ({
  value,
  label,
  color = "white",
}: HighlightValueProps) => {
  return (
    <div className="flex flex-row mb-auto">
      <StatusBar size="md" color={color} />
      <div className="ml-3 relative -my-1">
        <span className=" text-2xl font-bold block">{value}</span>
        <span className="text-xs text-focus-light block dark:text-focus-dark">
          {label}
        </span>
      </div>
    </div>
  );
};

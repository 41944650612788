import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { defaultLineColor } from "../../../chartjs/util";
import { GridItemSize } from "../../grid/item";
import { LegendItem } from "../../util/legend-item";
import { StateBoard } from "../base";
import { StateboardComponent } from "../../../types/stateboard";
import { useStateboard } from "../../../hooks/useStateboard";
import { getTimeFrameBySorting } from "../../../api/utils";
import { GRAPH_COLOR_ORDER } from "../../../constants/stateboards";
import { HistoricalData, SingleDataPoint } from "../../../api/types";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { HighlightValue } from "../../util/highlight-value";
import { useColorMode } from "../../../hooks/useColorMode";
import { numberToDotString } from "../../../util/number-strings";
import { TableList } from "../../util/table-list";

import { SVG_SIZE, svgConfig } from "../../../constants/svg-size";
import { ScDbArrowDown, ScDbArrowLeft, ScDbArrowUp } from "../../icons";
import { Point } from "../../util/point";
import { throwOnNoData } from "../../../util/throw-no-data";
import { Trend } from "../../util/trend";
import { VehicleType, getIconForVehicle } from "../traffic";
interface VehicleFrequencyDataData {
  points: SingleDataPoint[];
  value: number;
}
interface VehicleFrequencyData {
  name: string;
  type: VehicleType;
  data: {
    nord: VehicleFrequencyDataData;
    sud: VehicleFrequencyDataData;
    rathaus: VehicleFrequencyDataData;
  };
}

const RATHAUS = "71e09626-6b0d-42c4-91e1-1ddc59e03c0e";
const KNOEPFLESWAESCHERIN = "95a79150-a49c-4580-8397-aa380cc596e3";

const getVehicleValueKeys = (vehicles: VehicleType[]) => {
  return vehicles.flatMap((v) => [`${v}.out`, `${v}.in`]);
};

const Content = ({ data }: { data: HistoricalData[] }) => {
  const { t } = useTranslation();

  throwOnNoData(data);
  const { isLight } = useColorMode();

  const rathaus = useMemo(() => {
    return data.find((hd) => hd.labelIdentifier === RATHAUS)!;
  }, [data]);

  const knoepflesw = useMemo(() => {
    return data.find((hd) => hd.labelIdentifier === KNOEPFLESWAESCHERIN)!;
  }, [data]);

  const vehicles = useMemo(() => {
    const result: VehicleFrequencyData[] = [
      {
        data: {
          nord: { value: 0, points: [] },
          sud: { value: 0, points: [] },
          rathaus: { value: 0, points: [] },
        },
        name: "Radfahrende",
        type: VehicleType.BICYCLE,
      },
      {
        data: {
          nord: { value: 0, points: [] },
          sud: { value: 0, points: [] },
          rathaus: { value: 0, points: [] },
        },
        name: "Lieferverkehr",
        type: VehicleType.VAN,
      },
      {
        data: {
          nord: { value: 0, points: [] },
          sud: { value: 0, points: [] },
          rathaus: { value: 0, points: [] },
        },
        name: "Pkw",
        type: VehicleType.CAR,
      },
    ];

    const filterPoints = (vehicle: VehicleFrequencyData) => {
      return (p: SingleDataPoint) => p.valueKey.includes(vehicle.type);
    };

    result.forEach((vehicle) => {
      vehicle.data.nord.points = knoepflesw.singleDataPoints
        .filter(filterPoints(vehicle))
        .filter((sp) => sp.valueKey.includes("out"));

      vehicle.data.nord.value = vehicle.data.nord.points.reduce(
        (acc, curr) => acc + Number(curr.value),
        0
      );

      vehicle.data.sud.points = knoepflesw.singleDataPoints
        .filter(filterPoints(vehicle))
        .filter((sp) => sp.valueKey.includes("in"));

      vehicle.data.sud.value = vehicle.data.sud.points.reduce(
        (acc, curr) => acc + Number(curr.value),
        0
      );

      vehicle.data.rathaus.points = rathaus.singleDataPoints.filter(
        filterPoints(vehicle)
      );

      vehicle.data.rathaus.value = vehicle.data.rathaus.points.reduce(
        (acc, curr) => acc + Number(curr.value),
        0
      );
    });

    return result;
  }, [knoepflesw.singleDataPoints, rathaus.singleDataPoints]);

  const chartConfig = React.useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
        },
        y: {
          type: "linear",
        },
      },
    };
  }, []);

  const chartData = React.useMemo(() => {
    const allPoints = knoepflesw.singleDataPoints.concat(
      rathaus.singleDataPoints
    );

    const groupedData: Record<string, Record<string, number>> = {
      [VehicleType.BICYCLE]: {},
      [VehicleType.VAN]: {},
      [VehicleType.CAR]: {},
    };

    allPoints.forEach((p) => {
      const [vehicleType] = p.valueKey.split(".");
      const date = p.datetime;

      if (!groupedData[vehicleType]) {
        groupedData[vehicleType] = {};
      }

      if (!groupedData[vehicleType][date]) {
        groupedData[vehicleType][date] = 0;
      }

      groupedData[vehicleType][date] += Number(p.value);
    });

    const dataSets = vehicles.map((vehicle, i) => {
      const dataSets = {
        label: vehicle.name,
        data: Object.entries(groupedData[vehicle.type])
          .sort(
            ([aDate], [bDate]) =>
              new Date(aDate).getTime() - new Date(bDate).getTime()
          )
          .map(([date, value]) => ({
            x: new Date(date),
            y: isNaN(value) ? 0 : value,
          })),
        ...defaultLineColor(GRAPH_COLOR_ORDER[i]),
      };
      return dataSets;
    });

    const data = {
      datasets: dataSets,
    };
    return data;
  }, [knoepflesw.singleDataPoints, rathaus.singleDataPoints, vehicles]);

  const columns = useMemo(() => {
    const svgConfiguration = svgConfig({
      size: "md",
      props: { className: "absolute right-full pl-0.5" },
    });
    return ["Mobilitätsgrad", "Gesamt", "Nord", "Süd", "Rathaus"];
  }, []);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row justify-between  mb-3">
        <HighlightValue
          value={numberToDotString(
            vehicles.reduce((acc, curr) => {
              return (
                acc +
                curr.data.nord.value +
                curr.data.sud.value +
                curr.data.rathaus.value
              );
            }, 0)
          )}
          label="Nord, Süd & Rathaus 24h"
          color={isLight ? "dark" : "white"}
        />
        <div className="flex flex-col text-xs ">
          <div className="flex flex-row items-center mb-1">
            <ScDbArrowUp {...svgConfig({ size: "md" })} />
            <span className="ml-2 mr-4 text-light">Nord</span>
            <span className="ml-auto font-bold">
              {vehicles.reduce((acc, cur) => acc + cur.data.nord.value, 0)}
            </span>
          </div>
          <div className="flex flex-row items-center mb-1">
            <ScDbArrowDown {...svgConfig({ size: "md" })} />
            <span className="ml-2 mr-4 text-light">Süd</span>
            <span className="ml-auto font-bold">
              {vehicles.reduce((acc, cur) => acc + cur.data.sud.value, 0)}
            </span>
          </div>
          <div className="flex flex-row items-center mb-1">
            <ScDbArrowLeft {...svgConfig({ size: "md" })} />
            <span className="ml-2 mr-4 text-light">Rathaus</span>
            <span className="ml-auto font-bold">
              {vehicles.reduce((acc, cur) => acc + cur.data.rathaus.value, 0)}
            </span>
          </div>
        </div>
      </div>
      <TableList className="text-xs" columsNames={columns}>
        {vehicles.map((vehicle, i) => {
          if (!vehicle) {
            return null;
          }

          return (
            <TableList.Row className="">
              <TableList.Row.Item className="text-xs pt-1.5 pl-1">
                <div className="flex flex-row items-center">
                  <Point color={GRAPH_COLOR_ORDER[i]} className="mr-3" />
                  {getIconForVehicle(vehicle.type, { ...SVG_SIZE.xl })}
                  <span className="whitespace-nowrap ml-2">{vehicle.name}</span>
                </div>
              </TableList.Row.Item>
              <TableList.Row.Item className="text-xs pt-1.5 font-bold">
                {numberToDotString(
                  Object.values(vehicle.data).reduce(
                    (acc, curr) => acc + curr.value,
                    0
                  )
                )}
              </TableList.Row.Item>
              <TableList.Row.Item className="text-xs pt-1.5 pl-1 text-light">
                {numberToDotString(vehicle.data.nord.value)}
              </TableList.Row.Item>
              <TableList.Row.Item className="text-xs pt-1.5 pl-1 text-light">
                {numberToDotString(vehicle.data.sud.value)}
              </TableList.Row.Item>
              <TableList.Row.Item className="text-xs pt-1.5 pl-1 text-light">
                {numberToDotString(vehicle.data.rathaus.value)}
              </TableList.Row.Item>
            </TableList.Row>
          );
        })}
      </TableList>
      <div className="h-full mt-6 flex flex-col pb-2">
        <div className="flex flex-row mb-2">
          <p className="mr-auto text-light text-xs font-bold">
            Frequenzmessung gesamt
          </p>
        </div>
        <div className="basis-full">
          <Line
            height="100%"
            options={chartConfig as any}
            data={chartData}
          ></Line>
        </div>
      </div>
    </div>
  );
};

export const PersonFrequncyDetailStateboard: StateboardComponent = ({ id }) => {
  const { sorting, setSorting, timeFrame } = useStateboard();

  const { data, error, isLoading } = useHistoricalDataQuery({
    valueKey: getVehicleValueKeys([
      VehicleType.BICYCLE,
      VehicleType.VAN,
      VehicleType.CAR,
    ]).join(","),
    datetime__gte: timeFrame.start.toISOString(),
    labelIdentifier: [KNOEPFLESWAESCHERIN, RATHAUS].join(","),
  });
  return (
    <StateBoard
      size={GridItemSize.MAIN}
      loading={isLoading && !data}
      error={!!error}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title title={"Frequenzmessung Knöpfleswäscherin"} />
      <StateBoard.Content>{data && <Content data={data} />}</StateBoard.Content>
      {/* <StateBoard.Select /> */}
    </StateBoard>
  );
};

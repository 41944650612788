import React, { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DRAGGABLE_CANCEL_CLASS } from "../../../constants/grid";
import { NamedFC } from "../../../types/components";
import { findChild } from "../../../util/children";
import { Card } from "../../util/card";
import { GridItemSize } from "../../grid/item";
import { Select, SelectComponentType } from "../../util/select";
import { ToggleButtonGroup } from "../../util/toggel-button-group";
import { StateBoardContent, StateBoardContentComponentType } from "./content";
import { StateBoardTitle, StateBoardTitleComponentType } from "./title";
import loadingAnim from "../../../assets/lottie/sc_loading_state_v2_slower.json";
import Lottie from "lottie-react";
import { ScDbErrorFeBe } from "../../icons";
import { BlurredIcon } from "../../util/blurred-icon";
import { svgConfig } from "../../../constants/svg-size";
import { ErrorBoundary } from "../../error-boundary";
import { StateboardSorting } from "../../../api/types";
import { StateboardError } from "../../../util/throw-no-data";

export interface StateBoardProps {
  id: string;
}

export interface StateBoardBaseProps {
  children?: React.ReactNode;
  size?: GridItemSize;
  sorting?: StateboardSorting;
  loading?: boolean;
  error?: boolean;
  onSortingChange?: (value: StateboardSorting) => void;
}

export interface StateBoardComponent extends NamedFC<StateBoardBaseProps> {
  Title: StateBoardTitleComponentType;
  Select: SelectComponentType;
  Content: StateBoardContentComponentType;
}
const StateboardErrorComp = ({ error }: { error: unknown }) => {
  const { t } = useTranslation();
  let code = "1000";
  if (error instanceof StateboardError) {
    code = String(error.code);
  }

  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <div className="z-10 flex flex-col justify-center items-center text-center m-auto">
        <BlurredIcon
          Icon={
            <ScDbErrorFeBe
              {...svgConfig({
                affectedByDarkmode: false,
              })}
              width={30}
              height={30}
            />
          }
        />
        <span className="font-bold mt-4">{t("ERROR")}</span>
        <p className="mt-4 text-light text-xs">Err {code}</p>
      </div>
      <div className="bg-[#2E3137] rounded-lg z-0 opacity-10 dark:opacity-50 h-full w-full absolute top-0 left-0"></div>
    </div>
  );
};
const StateboardLoading = ({ loadingSize }: { loadingSize: string }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center flex items-center justify-center flex-col h-full">
      <div className={loadingSize}>
        <Lottie
          loop={true}
          autoplay={true}
          animationData={loadingAnim}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice",
            className: "icon-no-colormode",
          }}
        ></Lottie>
      </div>
      <div className="w-[140px]">
        <span>{t("LOADING_TEXT")}</span>
      </div>
    </div>
  );
};
const BoardContent = ({ content }: { content: ReactNode }) => {
  return (
    <div className="w-full h-full relative">
      <div className="absolute inset-0">{content}</div>
    </div>
  );
};
const StateBoard: StateBoardComponent = ({
  children,
  onSortingChange,
  sorting = "day",
  size = GridItemSize.MAIN,
  loading = false,
  error = false,
}) => {
  const { t, i18n } = useTranslation();
  const title = findChild(children, StateBoardTitle.displayName);
  const select = findChild(children, Select.displayName);
  const content = findChild(children, StateBoardContent.displayName);
  const [boundaryError, setBoundaryError] = useState<unknown>(false);
  const options = React.useMemo<
    { label: string; value: StateboardSorting }[]
  >(() => {
    return [
      { label: t("STATE_BOARD_FILTER_DAY"), value: "day" },
      { label: t("STATE_BOARD_FILTER_WEEK"), value: "week" },
      { label: t("STATE_BOARD_FILTER_MONTH"), value: "month" },
      { label: t("STATE_BOARD_FILTER_YEAR"), value: "year" },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, i18n.language]);
  const padding =
    size === GridItemSize.SMALL || size === GridItemSize.WIDE
      ? "pb-5"
      : "pb-10";
  const loadingSize =
    size === GridItemSize.SMALL || size === GridItemSize.WIDE
      ? "w-[70px]"
      : "w-[140px]";
  const negativePadding = size === GridItemSize.SMALL ? "md:-mx-3" : "";
  const inner = useMemo(() => {
    if (!!boundaryError) {
      return <StateboardErrorComp error={boundaryError} />;
    }
    if (loading) {
      return <StateboardLoading loadingSize={loadingSize} />;
    }
    return <BoardContent content={content} />;
  }, [boundaryError, content, loading, loadingSize]);

  return (
    <Card size="lg" className="h-full overflow-hidden overflow-x-auto">
      <ErrorBoundary
        onError={setBoundaryError}
        errorChild={<StateboardErrorComp error={boundaryError} />}
      >
        <article className="flex flex-col h-full ">
          <div
            className={`${
              !loading ? padding : ""
            } flex flex-col items-center justify-center`}
          >
            <div className="md:pb-1 pb-0">{title}</div>
            <div className={`min-w-[207px] ${DRAGGABLE_CANCEL_CLASS}`}>
              {select}
            </div>
          </div>
          <div className={`flex-1 ${negativePadding}`}>{inner}</div>
          <div className={DRAGGABLE_CANCEL_CLASS}>
            <ToggleButtonGroup
              onChange={onSortingChange}
              value={sorting}
              options={options}
            />
          </div>
        </article>
      </ErrorBoundary>
    </Card>
  );
};

StateBoard.displayName = "StateBoard";
StateBoard.Title = StateBoardTitle;
StateBoard.Select = Select;
StateBoard.Content = StateBoardContent;

export { StateBoard };

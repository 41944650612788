import React from "react";
import { SVGProps, memo } from "react";

const SvgLogoSmartCityHdhAa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={40}
    height={40}
    viewBox="0 0 40 40"
    {...props}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        clip-path: url(#clip-logo_smart_city_hdh_aa);\n      }\n\n      .cls-2 {\n        fill: url(#linear-gradient);\n      }\n    "
        }
      </style>
      <linearGradient
        id="linear-gradient"
        x1={0.057}
        y1={0.996}
        x2={0.997}
        y2={0.185}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#88ac3b" />
        <stop offset={1} stopColor="#054473" />
      </linearGradient>
      <clipPath id="clip-logo_smart_city_hdh_aa">
        <rect width={40} height={40} />
      </clipPath>
    </defs>
    <g id="logo_smart_city_hdh_aa" className="cls-1">
      <path
        id="nur_bildmarke"
        className="cls-2"
        d="M31.209,22.292H26.776V17.859a8.867,8.867,0,1,0-8.974,8.9h4.434v4.434H17.9A13.381,13.381,0,1,1,31.2,17.779Zm4.434-4.434A17.836,17.836,0,1,0,17.8,35.717h4.434v4.434h4.434V35.717H31.1v4.434h4.434V35.717H39.97V31.284H35.536V26.85H39.97V22.416H35.536V17.823M22.12,22.257h-4.23a4.38,4.38,0,1,1,4.38-4.38ZM26.554,31.2V26.77h4.434V31.2Z"
        transform="translate(0.029)"
      />
    </g>
  </svg>
);

const Memo = memo(SvgLogoSmartCityHdhAa);
export default Memo;

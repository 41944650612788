import maplibregl from "maplibre-gl";
import React, {
  forwardRef,
  memo,
  ReactComponentElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Lottie from "lottie-react";
import { DRAGGABLE_CANCEL_CLASS } from "../../../../constants/grid";
import { useColorMode } from "../../../../hooks/useColorMode";
import "./style.scss";
import loadingAnim from "../../../../assets/lottie/sc_loading_state_v2_slower.json";
import { filterChildren } from "../../../../util/children";
import { useMarkerFactory } from "../../../../hooks/useMarkerFactory";
const PADDING = {
  bottom: 40,
  left: 40,
  right: 40,
  top: 40,
};
const PITCH = 0;
interface MapProps {
  lat: number;
  lng: number;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  bearing?: number;
  interactive?: boolean;
  className?: string;
  pitch?: number;
  children?: React.ReactNode;
  keepZoom?: boolean;
  padding?: maplibregl.PaddingOptions;
}

const DEFAULTS = {
  zoom: 17,
  maxZoom: 20,
  minZoom: 6,
  pitch: PITCH,
  maxPitch: PITCH,
  minPitch: PITCH,
  interactive: false,
  bearing: 0,
};

export interface MarkerProps {
  children?: React.ReactNode;
  lng: number;
  lat: number;
  scaleWidthZoom?: boolean;
  options?: maplibregl.MarkerOptions;
}

const Marker = ({ children, options }: MarkerProps) => {
  return <div></div>;
};
Marker.displayName = "MapMarker";

const buildBounds = (markers: maplibregl.Marker[]) => {
  const bounds = new maplibregl.LngLatBounds();
  markers.forEach((m) => bounds.extend(m.getLngLat()));
  return bounds;
};

const MapComponent = memo(
  forwardRef<maplibregl.Map, MapProps>(
    (
      {
        lng,
        lat,
        className,
        children,
        keepZoom = false,
        padding = PADDING,
        ...mapOptions
      },
      forwardMapRef
    ) => {
      const mapElementRef = useRef<HTMLDivElement | null>(null);
      const markers = useRef<maplibregl.Marker[]>([]);
      const [mapRef, setMapRef] = useState<maplibregl.Map | null>(null);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState<unknown>(false);
      const { colorMode } = useColorMode();
      const createMarker = useMarkerFactory();

      useEffect(() => {
        resize();
      });

      const resize = useCallback(() => {
        const map = mapRef;
        if (!map) return;

        map.resize();

        if (markers.current.length > 0) {
          map.fitBounds(buildBounds(markers.current), {
            padding,
          });
          if (mapOptions.zoom && !keepZoom) {
            map.setZoom(mapOptions.zoom);
          }
        }
      }, [keepZoom, mapOptions.zoom, mapRef, padding]);

      const markerChildren = useMemo(
        () => filterChildren(children, Marker.displayName),
        [children]
      ) as ReactElement<MarkerProps>[];

      const styleUrl = useMemo(() => {
        return colorMode === "dark"
          ? `${window.settings.TILE_SERVER_URL}styles/dark/style.json/`
          : `${window.settings.TILE_SERVER_URL}styles/light/style.json/`;
      }, [colorMode]);

      const center = useMemo<[number, number]>(() => [lng, lat], [lng, lat]);

      useEffect(() => {
        setMapRef((oldMap) => {
          if (oldMap) {
            return oldMap;
          }

          if (!mapElementRef.current) return null;

          const newMap = new maplibregl.Map({
            ...DEFAULTS,
            container: mapElementRef.current,
            style: styleUrl,
            center: center,
            ...mapOptions,
          });

          newMap.on("load", () => {
            setLoading(false);
          });

          newMap.on("error", (e) => {
            setError(e);
            process.env.NODE_ENV === "development" && console.error(e);
          });

          if (typeof forwardMapRef === "function") {
            forwardMapRef(newMap);
          } else if (forwardMapRef) {
            forwardMapRef.current = newMap;
          }

          resize();
          return newMap;
        });
      }, [center, forwardMapRef, mapOptions, resize, styleUrl]);

      useEffect(() => {
        if (!mapRef) return;

        // Update settings
        mapRef.setCenter(center);
        resize();
      }, [center, mapRef, resize]);

      useEffect(() => {
        if (!mapRef) return;

        // Update settings
        mapRef.setZoom(mapOptions.zoom ?? DEFAULTS.zoom);
        resize();
      }, [mapOptions.zoom, mapRef, resize]);
      useEffect(() => {
        if (!mapRef) return;

        // Update settings
        mapRef.setMaxZoom(mapOptions.maxZoom ?? DEFAULTS.maxZoom);
        resize();
      }, [mapOptions.maxZoom, mapRef, resize]);

      useEffect(() => {
        if (!mapRef) return;

        // Update settings
        mapRef.setMinZoom(mapOptions.minZoom ?? DEFAULTS.minZoom);
        resize();
      }, [mapOptions.minZoom, mapRef, resize]);

      useEffect(() => {
        if (!mapRef) return;
        mapRef._interactive = mapOptions.interactive ?? DEFAULTS.interactive;
      }, [mapOptions.interactive, mapRef, resize]);

      useEffect(() => {
        mapRef?.setStyle(styleUrl);
      }, [mapRef, styleUrl]);

      useEffect(() => {
        const map = mapRef;
        if (!map) return;
        markers.current.forEach((m) => m.remove());
        markers.current = [];

        markerChildren.forEach((mc) => {
          if (mc.props.scaleWidthZoom) {
            const zoom = map.getZoom();
          }

          const marker = createMarker(
            <div>{mc.props.children}</div>,
            mc.props.options
          );
          marker.setLngLat([mc.props.lng, mc.props.lat]);
          markers.current.push(marker);
          marker.addTo(map);
        });

        resize();
      }, [createMarker, mapRef, markerChildren, resize]);

      useEffect(() => {
        let timeOut: NodeJS.Timeout | null = null;
        const onResize = () => {
          timeOut = setTimeout(() => {
            resize();
          }, 300);
        };
        window.addEventListener("resize", onResize);
        return () => {
          timeOut && clearTimeout(timeOut);
          window.removeEventListener("resize", onResize);
        };
      }, [resize]);

      return (
        <div
          className={`${DRAGGABLE_CANCEL_CLASS} w-full h-full relative ${className} overflow-hidden rounded-sm border dark:border-active-dark border-active-light`}
        >
          {loading && (
            <div className="w-full h-full flex flex-col items-center">
              <div className="h-24 w-24 m-auto">
                <Lottie
                  loop={true}
                  autoplay={true}
                  animationData={loadingAnim}
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                    className: "icon-no-colormode",
                  }}
                />
              </div>
            </div>
          )}
          <div
            className={loading ? "hidden" : "h-full w-full"}
            ref={mapElementRef}
          ></div>
        </div>
      );
    }
  )
);
export const Map = Object.assign(MapComponent, { Marker });

import { SVGProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SVG_SIZE } from "../../../constants/svg-size";
import {
  ScDbBewoelktColor,
  ScDbGewitterColor,
  ScDbNurBewoelktColor,
  ScDbRainColor,
  ScDbSnowColor,
  ScDbSunColor,
} from "../../icons";

export enum WeatherDescription {
  SUNNY = "SUNNY",
  SUNNY_CLOUDY = "SUNNY_CLOUDY",
  CLOUDY = "CLOUDY",
  RAIN = "RAIN",
  SNOW = "SNOW",
  STORM = "STORM",
}

export interface WeatherProps {
  description: WeatherDescription;
  temperature: string;
}

const getIconForDescription = (
  desc: WeatherDescription
): React.MemoExoticComponent<
  (props: SVGProps<SVGSVGElement>) => JSX.Element
> => {
  switch (desc) {
    case WeatherDescription.SUNNY:
      return ScDbSunColor;
    case WeatherDescription.SUNNY_CLOUDY:
      return ScDbBewoelktColor;
    case WeatherDescription.CLOUDY:
      return ScDbNurBewoelktColor;
    case WeatherDescription.RAIN:
      return ScDbRainColor;
    case WeatherDescription.SNOW:
      return ScDbSnowColor;
    case WeatherDescription.STORM:
      return ScDbGewitterColor;
    default:
      return ScDbSunColor;
  }
};

export const Weather = ({ description, temperature }: WeatherProps) => {
  const { t } = useTranslation();
  const Icon = useMemo(() => {
    return getIconForDescription(description);
  }, [description]);

  return (
    <div className="flex flex-row mb-1 mr-5">
      <div>
        <Icon {...SVG_SIZE.xxl}></Icon>
      </div>
      <div className="ml-3 flex flex-col">
        <span className="text-2xl font-bold">{parseInt(temperature)}°C</span>
        <span className="text-sm">{t(description)}</span>
      </div>
    </div>
  );
};

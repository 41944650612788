import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbLkw = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="ad5ca07f-fc7b-4c19-85e2-30b368b839f9" data-name="icon">
      <rect
        x={10.84}
        y={5}
        width={10.64}
        height={6.78}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polygon
        points="10.84 11.78 4.62 11.78 6.19 7.86 10.84 7.86 10.84 11.78"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={14.48}
        y1={16.94}
        x2={8.81}
        y2={16.94}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="4.7 16.95 2.52 16.95 2.52 15.57 2.52 12.8 4.42 11.78 21.48 11.78 21.48 16.95 18.85 16.95"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={6.75}
        cy={16.94}
        r={2.06}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={16.68}
        cy={16.94}
        r={2.06}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbLkw);
export default Memo;

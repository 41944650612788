import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { HistoricalData, StateboardSorting } from "../../../api/types";
import { useStateboard } from "../../../hooks/useStateboard";
import {
  StateboardComponent,
  StateboardContentComponent,
} from "../../../types/stateboard";
import { throwOnNoData } from "../../../util/throw-no-data";
import { GridItemSize } from "../../grid/item";
import {
  FillCircle,
  FillCircleItem,
  FillCircleSingle,
} from "../../util/fill-circle";
import {
  SimplePointList,
  SimplePointListItem,
} from "../../util/simple-point-list";
import { TableList } from "../../util/table-list";
import { TrendIcon } from "../../util/trend";
import { StateBoard } from "../base";

const FEINSTAUB_GROUP_IDENTIFIER = "78e89a84-b206-4b75-96a4-3aca3f722aa0";
const UMWELT_GROUP_IDENTIFIER = "d005a790-eded-4cde-a090-06a55c3874d5";

interface ContentProps {
  sorting: StateboardSorting;
  data: HistoricalData[];
}

const Content = ({ data, sorting }: ContentProps) => {
  const { t } = useTranslation();

  throwOnNoData(data);

  const pointList = useMemo<SimplePointListItem[]>(() => {
    const fein = data.find(
      (h) => h.groupIdentifier === FEINSTAUB_GROUP_IDENTIFIER
    )!;

    const pressure =
      Number(
        fein.singleDataPoints.find((p) => p.valueKey === "barometric_pressure")!
          .value
      ) / 100;

    const humidity = Number(
      fein.singleDataPoints.find((p) => p.valueKey === "humidity")!.value
    ).toFixed(1);

    const dustPM10 = fein.singleDataPoints.find(
      (p) => p.valueKey === "particle.PM10.mass"
    )!.value;
    const dustPM2dot5 = fein.singleDataPoints.find(
      (p) => p.valueKey === "particle.PM2.5.mass"
    )!.value;

    return [
      {
        label: t("WEATHER_STATEBOARD_HUMIDITY"),
        value: humidity + "%",
        color: "white",
      },
      {
        label: t("WEATHER_STATEBOARD_PRESSURE"),
        value: pressure.toFixed(1) + " hPa",
        color: "white",
      },
      {
        label: t("WEATHER_STATEBOARD_FINEDUST"),
        value: dustPM2dot5 + " / " + dustPM10,
        color: "bright-green",
      },
    ];
  }, [data, t]);

  return (
    <div className="flex flex-row h-full justify-center">
      <div className="w-full flex flex-row justify-center">
        <SimplePointList items={pointList} />
      </div>
    </div>
  );
};

export const EnvironmentStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();
  const { sorting, setSorting, timeFrame } = useStateboard();
  const { isLoading, data, isError } = useHistoricalDataQuery({
    datetime__gte: timeFrame.start.toISOString(),
    groupIdentifier: [FEINSTAUB_GROUP_IDENTIFIER].join(","),
    valueKey: [
      "humidity",
      "barometric_pressure",
      "particle.PM10.mass",
      "particle.PM2.5.mass",
    ].join(","),
  });

  return (
    <StateBoard
      size={GridItemSize.SMALL}
      error={isError}
      loading={isLoading}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title
        title={t("ENVIRONMENT_STATEBOARD_NAME")}
      ></StateBoard.Title>
      <StateBoard.Content>
        {data && <Content sorting={sorting} data={data} />}
      </StateBoard.Content>
    </StateBoard>
  );
};

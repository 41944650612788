import { Layout } from "../layout";
import bgImgDark from "../../../assets/img/graphic_logIn_02.png";
import bgImgLight from "../../../assets/img/graphic_logIn_02_light.png";
import { TextInput } from "../../util/common/text-input";
import { Button } from "../../util/common/button";
import { useTranslation } from "react-i18next";
import { FormEvent, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, login } from "../../../api/requests";
import { useColorMode } from "../../../hooks/useColorMode";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const client = useQueryClient();
  const navigate = useNavigate();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login({ username, password });
      client.invalidateQueries(["user"]);
      //TODO: Navigate to user page
      navigate("/");
      setMessage("");
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.res.status === 403 || error.res.status === 401) {
          setMessage(t("INVALID_EMAIL_PASSWORD"));
        } else {
          setMessage(t("ERROR"));
        }
      } else {
        console.error(error);
        setMessage(t("ERROR"));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <h2 className="text-lg text-center pb-5 leading-8 font-bold">
        {t("LOGIN_HEADLINE_P1")}
        <br /> {t("LOGIN_HEADLINE_P2")}
      </h2>
      <div className="flex-col flex">
        <div className="pb-5 flex-col flex">
          <label className="text-light" htmlFor="username">
            {t("USERNAME")}
          </label>
          <TextInput
            required
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></TextInput>
        </div>
        <div className="pb-5 flex-col flex">
          <label className="text-light" htmlFor="password">
            {t("EMPLOYEE_ID")}
          </label>
          <TextInput
            required
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></TextInput>
        </div>
        <Button loading={loading} type="submit">
          {t("LOG_IN")}
        </Button>
        <span className="text-bad pt-5 min-h-[46px]">{message}</span>
      </div>
    </form>
  );
};

export const LoginPage = () => {
  const { isDark, isLight } = useColorMode();
  return (
    <Layout>
      <div className="w-full h-full relative overflow-hidden pt-5">
        <div className=" w-full ">
          <img
            src={bgImgLight}
            alt="Smart City Illustration"
            aria-hidden={isDark}
            className={`${
              isDark ? "opacity-0" : ""
            } absolute top-0 object-cover left-0 w-full h-full transition-all`}
          ></img>
          <img
            src={bgImgDark}
            alt="Smart City Illustration"
            aria-hidden={isLight}
            className={`${
              isLight ? "opacity-0" : ""
            } absolute top-0 object-cover left-0 w-full h-full transition-all`}
          ></img>
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2 absolute left-0 top-0 bottom-0 bg-bg-secondary-light dark:bg-bg-secondary-dark">
          <div className="flex flex-row w-full h-full justify-center items-center">
            <div className="max-w-sm">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

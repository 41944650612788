import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../../../hooks/useBreakpoint";

const getCurrentTime = (locales?: string) => {
  const date = new Date();
  return date.toLocaleString(locales, {
    dayPeriod: "narrow",
    hour: "numeric",
    minute: "numeric",
  });
};

export const CurrentTime = () => {
  const { t, i18n } = useTranslation();
  const [time, setTime] = useState(getCurrentTime(i18n.language));
  const breakpoint = useBreakpoint();
  useEffect(() => {
    setTime(getCurrentTime(i18n.language));
  }, [i18n.language]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getCurrentTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <span>
      {time} {breakpoint !== "xs" && t("CURRENT_TIME_SUFFIX")}
    </span>
  );
};

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbParkenBig = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="b266d03f-4a36-46e0-856a-1b4a57d0b91b" data-name="icon">
      <path
        d="M8.62,20V4h4.65c5.49,0,5.49,8.62,0,8.62H8.62"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbParkenBig);
export default Memo;

import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbGarbageRed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="b17cd822-e363-427e-a601-a3805189d09f"
        cx={11.81}
        cy={12}
        r={6.47}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fa0504" stopOpacity={0.8} />
        <stop offset={0.12} stopColor="#fa0504" stopOpacity={0.73} />
        <stop offset={0.36} stopColor="#fa0504" stopOpacity={0.56} />
        <stop offset={0.7} stopColor="#fa0504" stopOpacity={0.27} />
        <stop offset={1} stopColor="#fa0504" stopOpacity={0} />
      </radialGradient>
    </defs>
    <g id="e4950a7f-b493-4222-8290-c2bb1fea7e84" data-name="color">
      <path
        d="M14.54,7.39V5.5a1,1,0,0,0-1-1H10.07a1,1,0,0,0-1,1V7.39H6.56V18.5a1,1,0,0,0,1,1h8.5a1,1,0,0,0,1-1V7.39Z"
        fill="url(#b17cd822-e363-427e-a601-a3805189d09f)"
      />
    </g>
    <g id="f8192567-8bf0-4b16-9e4f-cf93695bfe8b" data-name="icon">
      <path
        d="M6.56,7.39h10.5a0,0,0,0,1,0,0V18.5a1,1,0,0,1-1,1H7.56a1,1,0,0,1-1-1V7.39A0,0,0,0,1,6.56,7.39Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <line
        x1={4.6}
        y1={7.36}
        x2={19.02}
        y2={7.36}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        d="M10.07,4.5h3.47a1,1,0,0,1,1,1V7.36a0,0,0,0,1,0,0H9.07a0,0,0,0,1,0,0V5.5A1,1,0,0,1,10.07,4.5Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <line
        x1={10.06}
        y1={10.38}
        x2={10.06}
        y2={16.64}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <line
        x1={13.55}
        y1={10.38}
        x2={13.55}
        y2={16.64}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbGarbageRed);
export default Memo;

import { useEffect } from "react";
import { useColorMode } from "../../hooks/useColorMode";
import { Chart as ChartJS } from "chart.js";
import { TAILWIND_COLORS } from "../../util/color";
import { useTranslation } from "react-i18next";
import { de, enUS } from "date-fns/locale";
import "chartjs-adapter-date-fns";

export const ChartColorSubscriber = () => {
  const { colorMode } = useColorMode();
  const { i18n } = useTranslation();
  useEffect(() => {
    const gridColor =
      colorMode === "dark"
        ? TAILWIND_COLORS["inactive-dark"]
        : TAILWIND_COLORS["inactive-light"];
    const color =
      colorMode === "dark"
        ? TAILWIND_COLORS["focus-dark"]
        : TAILWIND_COLORS["focus-light"];

    try {
      ChartJS.defaults.scale.grid.color = gridColor;

      ChartJS.defaults.color = color;

      ChartJS.defaults.scales.time.adapters.date = {
        locale: i18n.language === "de" ? de : enUS,
      };

      Object.values(ChartJS.instances).forEach((chart) => {
        chart.options.color = ChartJS.defaults.color;
        if (chart.options.scales) {
          Object.values(chart.options.scales).forEach((scale) => {
            if (scale?.grid) {
              scale.grid.color = gridColor;
            }
          });
        }
        chart.update("none");
      });
    } catch (e) {
      process.env.NODE_ENV === "development" && console.error(e);
    }
  }, [colorMode, i18n.language]);

  return <></>;
};

import { getBgForColor, ScColor } from "../../../util/color";

interface StatusBarProps {
  color?: ScColor;
  size: "md" | "sm";
  className?: string;
}

export const StatusBar = ({
  color = "white",
  size,
  className,
}: StatusBarProps) => {
  const bgColor = getBgForColor(color);
  const width = size === "md" ? "w-1" : "w-[3px]";
  return (
    <div
      className={`relative ${width} block rounded-tl-[4px] rounded-bl-[4px] ${bgColor} ${className}`}
    >
      <div className={`blur-sm ${width} h-full top-0 left-0 ${bgColor}`}></div>
    </div>
  );
};

import { StateboardSorting } from "../types";

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = DAY * 7;
const MONTH = WEEK * 4;
const YEAR = MONTH * 12;

export const getTimeFrameBySorting = (
  sorting: StateboardSorting,
  specificDate: Date | null = null
) => {
  const now = new Date();
  let back: number = 0;

  switch (sorting) {
    case "day":
      back = DAY;
      break;
    case "week":
      back = WEEK;
      break;
    case "month":
      back = MONTH;
      break;
    case "year":
      back = YEAR;
      break;
  }
  if (specificDate) {
    return {
      start: new Date(specificDate.getTime() - back),
      end: specificDate,
    };
  }
  return {
    start: new Date(now.getTime() - back),
    end: now,
  };
};

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbBehindertenparkplatz = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="a0b2f46b-1764-43ec-9055-7c3b5334084d" data-name="icon">
      <polyline
        points="16.99 18.07 13.87 13.84 9.6 13.82 9.6 8.37"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <circle cx={10.09} cy={5.57} r={1.54} fill="#fff" />
      <line
        x1={11.26}
        y1={11.56}
        x2={13.76}
        y2={11.56}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <line
        x1={16.99}
        y1={18.07}
        x2={18.26}
        y2={16.92}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
      <path
        d="M14.06,17.24A4.31,4.31,0,1,1,7.94,11.9"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbBehindertenparkplatz);
export default Memo;

import { ScColor } from "../../../util/color";
import { StatusBar } from "../../content/status-bar";
export interface SimplePointListItem {
  label: string;
  value: string;
  color?: ScColor;
}
const ListItem = ({ label, value, color }: SimplePointListItem) => {
  return (
    <div className="flex flex-row text-xs justify-between p-1 ">
      <div className="flex flex-row items-center">
        <StatusBar color={color} size={"sm"} className="h-6 " />
        <span className="ml-2.5 dark:text-focus-dark text-focus-light">
          {label}
        </span>
      </div>
      <div className="ml-4 text-right flex flex-row items-center">
        <span className="font-bold">{value}</span>
      </div>
    </div>
  );
};

export interface SimplePointListProps {
  items: SimplePointListItem[];
}

export const SimplePointList = ({ items }: SimplePointListProps) => {
  return (
    <div className="flex flex-col w-full">
      {items.map((item) => (
        <ListItem key={item.label} {...item}></ListItem>
      ))}
    </div>
  );
};

import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbKurzzeitparkplatz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="a6217d2f-4c49-4357-9d51-081517da6d58"
    data-name="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <polyline
      points="12 7.01 12 12.57 14.9 15.01"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={12}
      cy={12}
      r={9.5}
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
    />
  </svg>
);

const Memo = memo(SvgScDbKurzzeitparkplatz);
export default Memo;

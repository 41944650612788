import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "../../../hooks/useBreakpoint";

export const CurrentDate = () => {
  const { i18n } = useTranslation();
  const [date, setDate] = useState(new Date());
  const breakpoint = useBreakpoint();

  const dateTimeFormat: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  if (breakpoint === "xs") {
    delete dateTimeFormat.weekday;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, [i18n.language]);

  return <span>{date.toLocaleDateString(i18n.language, dateTimeFormat)}</span>;
};

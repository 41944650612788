import { useBreakpoint } from "../../../../hooks/useBreakpoint";
import { CurrentDate } from "../../../content/current-date";
import { CurrentTime } from "../../../content/current-time";
import { CurrentWeather } from "../../../content/current-weather";

const Spacer = () => {
  return (
    <div
      className="h-4 mx-2 md:mx-5 dark:bg-info-dark bg-info-light"
      style={{ width: 2 }}
    ></div>
  );
};
export const StatusBar = () => {
  return (
    <div className="flex items-center text-sm flex-row">
      <div>
        <CurrentDate />
      </div>
      <Spacer />
      <div>
        <CurrentTime />
      </div>
    </div>
  );
};

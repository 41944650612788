import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbElektroparkplatz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="uuid-387119ab-6918-4a2b-bf02-ed548eb2732f"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="m8.64,13.12l-1.1,1.1c-1.19,1.19-1.19,3.11,0,4.3h0c1.28,1.28,3.37,1.28,4.65,0l7.29-7.29"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g>
      <path
        d="m9.19,6.7h5.99v3.23c0,1.38-1.12,2.5-2.5,2.5h-.99c-1.38,0-2.5-1.12-2.5-2.5v-3.23h0Z"
        transform="translate(10.33 -5.82) rotate(45)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={14.69}
        y1={4.52}
        x2={13.25}
        y2={5.96}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={17.23}
        y1={7.06}
        x2={15.79}
        y2={8.5}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={8.06}
        y={11.34}
        width={2.99}
        height={1.71}
        transform="translate(11.42 -3.18) rotate(45)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbElektroparkplatz);
export default Memo;

import { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useColorMode } from "../../../hooks/useColorMode";

export const ToggleColorMode = () => {
  const { colorMode, isLight, setColorMode } = useColorMode();
  const { t } = useTranslation();
  // const onToggle = useCallback<ChangeEventHandler<HTMLInputElement>>(
  //   (e) => {
  //     setColorMode(e.target.checked ? "dark" : "light");
  //   },
  //   [setColorMode]
  // );
  const style = useMemo<CSSProperties>(() => {
    // const margin = 4;
    return {
      transition: "ease-in-out left 150ms",
      left: colorMode === "dark" ? "0" : "20px",
    };
  }, [colorMode]);

  const handleClick = () => {
    setColorMode(colorMode === "light" ? "dark" : "light");
  };

  return (
    <div
      role={"button"}
      tabIndex={0}
      aria-label={t("ACCESSIBILITY.TOGGLE_COLOR_MODE_LABEL", {
        to: isLight ? t("COLOR_MODE_DARK") : t("COLOR_MODE_LIGHT"),
      })}
      onClick={handleClick}
      className="h-6 w-12 flex flex-col justify-center relative rounded-md bg-inactive-light dark:bg-inactive-dark"
    >
      <div
        style={style}
        className="mx-1 absolute h-5 w-5 rounded-full dark:bg-active-dark bg-active-light"
      ></div>
    </div>
  );
};

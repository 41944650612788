import { NamedFC } from "../../../types/components";

export interface StateBoardContentProps {
  children?: React.ReactNode;
}

export type StateBoardContentComponentType = NamedFC<StateBoardContentProps>;

export const StateBoardContent: StateBoardContentComponentType = ({
  children,
}) => {
  return <>{children}</>;
};
StateBoardContent.displayName = "StateBoardContent";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { StateboardSorting } from "../../../api/types";
import { GRAPH_COLOR_ORDER } from "../../../constants/stateboards";
import { useStateboard } from "../../../hooks/useStateboard";
import {
  StateboardComponent,
  StateboardContentComponent,
} from "../../../types/stateboard";
import { TAILWIND_COLORS } from "../../../util/color";
import { throwOnNoData } from "../../../util/throw-no-data";
import { GridItemSize } from "../../grid/item";
import {
  FillCircle,
  FillCircleItem,
  FillCircleSingle,
} from "../../util/fill-circle";
import { Point } from "../../util/point";
import { TableList } from "../../util/table-list";
import { TrendIcon } from "../../util/trend";
import { StateBoard } from "../base";
import { numberToDotString } from "../../../util/number-strings";

const Content: StateboardContentComponent<{ sorting: StateboardSorting }> = ({
  data,
  sorting,
}) => {
  const { t } = useTranslation();
  const padding = "pb-2 pr-3";
  throwOnNoData(data, true);

  const boxData = useMemo(() => {
    return (
      data
        // longest one first
        .sort((a, b) => b.name.length - a.name.length)
        .map((box, i) => {
          const name = i === 0 ? "Sammelgarage" : "Einzelstellplätze";
          const avg =
            box.singleDataPoints.reduce(
              (acc, cur) => acc + Number(cur.value),
              0
            ) / box.singleDataPoints.length;
          const percAvg = (avg / Number(box.misc.sum)) * 100;
          const perc = (
            (Number(box.singleDataPoints[0]?.value || 0) /
              Number(box.misc.sum)) *
            100
          ).toFixed(1);
          const fillCircle: FillCircleItem = {
            label: name,
            percantage: Number(perc),
            value: perc + "%",
            color: TAILWIND_COLORS[GRAPH_COLOR_ORDER[i]],
          };
          return {
            name,
            box,
            fillCircle,
            avg,
            perc,
            percAvg: isNaN(percAvg) ? "-" : percAvg.toFixed(1),
          };
        })
    );
  }, [data]);

  const columns = [
    t("BIKE_BOX_KIND_OF_BOX"),
    t("BIKE_BOX_OCCUPANCY"),
    "Durchsch.",
  ];

  const cirlces = boxData.map((bd) => bd.fillCircle);

  return (
    <div className="flex flex-row  justify-between mb-auto">
      <div className="flex flex-col">
        <FillCircle items={[cirlces[0], cirlces[1]]} size="small" />
      </div>
      <div className="flex w-full mx-8   flex-col items-center justify-center text-xs whitespace-nowrap">
        <TableList columsNames={columns}>
          {boxData.map((bd, i) => {
            return (
              <TableList.Row>
                <TableList.Row.Item>
                  <div className="flex flex-row items-center  mt-1">
                    <Point color={GRAPH_COLOR_ORDER[i]} />
                      <span className="ml-1.5">{bd.name}</span>
                    <div className="overflow-hidden text-ellipsis  text-light">
                    </div>
                  </div>
                </TableList.Row.Item>
                <TableList.Row.Item>
                  <div className="mt-1 pr-6">
                    <span>{bd.box.singleDataPoints[0]?.value ?? "-"}</span>
                    <span className="text-light">
                      {" / "}
                      {bd.box.misc.sum}
                    </span>
                  </div>
                </TableList.Row.Item>
                <TableList.Row.Item>
                  <div className="mt-1 text-light">
                    <div className="relative bottom-0.5">
                      <span className="mr-1 text-md">∅</span>
                      <span>{bd.percAvg}%</span>
                    </div>
                  </div>
                </TableList.Row.Item>
              </TableList.Row>
            );
          })}
        </TableList>
      </div>
    </div>
  );
};

export const BikeBoxStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();
  const { sorting, setSorting, timeFrame } = useStateboard();
  const { isLoading, data, error } = useHistoricalDataQuery({
    valueKey: "occupied",
    datetime__gte: timeFrame.start.toISOString(),
    groupIdentifier: "b84fcd90-f870-4f75-8793-e7ba7526b07f",
  });

  return (
    <StateBoard
      size={GridItemSize.SMALL}
      error={!!error}
      loading={isLoading}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title
        title={t("BIKE_BOX_STATEBOARD_TITLE")}
      ></StateBoard.Title>
      <StateBoard.Content>
        {data && <Content sorting={sorting} data={data} />}
      </StateBoard.Content>
    </StateBoard>
  );
};

import { ScColor } from "../../../util/color";
import { Point } from "../point";

export interface LegendItemProps {
  color: ScColor;
  name: string;
  value: number | string;
  unit?: string;
}

export const LegendItem = ({
  color,
  name,
  value,
  unit,
}: LegendItemProps): JSX.Element => {
  return (
    <div className="flex flex-row items-center ml-7 first:ml-0 text-xs">
      <Point color={color} className="mr-3" />
      <span>
        {name}:
        <span className="ml-1.5 font-bold">
          {value} {unit}
        </span>
      </span>
    </div>
  );
};

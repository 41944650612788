import { FCWithChildren } from "../../../types/components";
import { Size } from "../../../types/util";
// For Tailwind to JIT the classnames

const shadowClassNamesForSize: Record<Size, string> = {
  sm: "shadow-sm-light dark:shadow-sm-dark",
  md: "shadow-md-light dark:shadow-md-dark",
  lg: "shadow-lg-light dark:shadow-lg-dark",
  xl: "shadow-xl-light dark:shadow-xl-dark",
};

const roundedClassNamesForSize: Record<Size, string> = {
  sm: "rounded-sm",
  md: "rounded-md",
  lg: "rounded-lg",
  xl: "rounded-xl",
};

export interface CardProps {
  size?: Size;
  className?: string;
  gradient?: boolean;
}

export const Card: FCWithChildren<CardProps> = ({
  children,
  size = "xl",
  gradient = true,
  className,
}) => {
  const bgClass = gradient
    ? "bg-gradient"
    : "dark:bg-inactive-dark bg-inactive-light";
  const shadowClass = shadowClassNamesForSize[size];
  const roundedClass = roundedClassNamesForSize[size];

  return (
    <div
      className={`pt-4 md:px-8 px-2.5 pb-2.5 ${bgClass} ${shadowClass} ${roundedClass} ${className}`}
    >
      {children}
    </div>
  );
};

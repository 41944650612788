import { memo, SVGProps } from "react";

export interface BlurredIconProps extends SVGProps<SVGSVGElement> {
  Icon: JSX.Element;
}

export const BlurredIcon = memo(({ Icon }: BlurredIconProps) => {
  return (
    <div className="relative">
      {Icon}
      <div className="absolute top-0 left-0 blur-[2.4px]">{Icon}</div>
    </div>
  );
});

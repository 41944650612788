import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="uuid-92fff4be-ca48-4db6-8f16-15ee0a5abb7a"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 13"
    {...props}
  >
    <g id="uuid-ee53b225-5cd4-4ef2-8f19-32f8ac271d2e">
      <path
        id="uuid-fbff3625-3f46-47dc-83d2-407fb70c9230"
        d="m10.5,7.24l-4.81,4.81L.88,7.24"
        fill="none"
        stroke="#94a1b4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
      <line
        id="uuid-38838824-f9a9-4fbd-b1f7-3fdbbe5c4fa6"
        x1={5.68}
        y1={11.63}
        x2={5.68}
        y2={0.95}
        fill="none"
        stroke="#94a1b4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbArrowDown);
export default Memo;

import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="uuid-a144a117-5f23-44ca-af3d-4de3eb2f5493"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 13"
    {...props}
  >
    <g id="uuid-b8e4d86f-53d4-472a-b1ba-267fd82b921f">
      <path
        id="uuid-fecd9d64-9c23-476d-831a-cd78e16d36db"
        d="m.88,5.76L5.69.95l4.81,4.81"
        fill="none"
        stroke="#94a1b4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
      <line
        id="uuid-a58b24cc-3301-46d0-9b97-7f10c68f8ecc"
        x1={5.7}
        y1={1.37}
        x2={5.7}
        y2={12.05}
        fill="none"
        stroke="#94a1b4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbArrowUp);
export default Memo;

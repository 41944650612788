import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbVan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g id="b9ca6a4f-ff22-481d-b024-83abaf50e92a" data-name="icon">
      <polyline
        points="10.84 6.72 21.48 6.72 21.48 10.64"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="4.62 10.64 6.19 6.72 10.84 6.72"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={14.48}
        y1={15.81}
        x2={8.81}
        y2={15.81}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="21.48 10.64 21.48 15.8 18.85 15.8"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="4.7 15.8 2.52 15.8 2.52 14.44 2.52 11.66 4.62 10.64 4.62 10.64"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={6.75}
        cy={15.81}
        r={2.06}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={16.68}
        cy={15.81}
        r={2.06}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="4.62 10.64 10.21 10.64 10.21 6.88"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbVan);
export default Memo;

import * as React from "react";
import { SVGProps, memo } from "react";

const SvgScDbTaxiparkplatz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="ada78e14-08f5-46f2-a7f5-aa6eec4d3b0d"
    data-name="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M17.82,3H5.93a1,1,0,0,0,0,2h5V20a1,1,0,0,0,2,0V5h4.94a1,1,0,0,0,0-2Z"
      fill="#fff"
    />
  </svg>
);

const Memo = memo(SvgScDbTaxiparkplatz);
export default Memo;

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbSunColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="a83a17d9-82b9-4886-87d0-422768d6e7c3"
        cx={12.14}
        cy={11.95}
        r={5.64}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f4c914" stopOpacity={0.8} />
        <stop offset={0.12} stopColor="#f4c914" stopOpacity={0.73} />
        <stop offset={0.37} stopColor="#f4c914" stopOpacity={0.55} />
        <stop offset={0.71} stopColor="#f4c914" stopOpacity={0.26} />
        <stop offset={1} stopColor="#f4c914" stopOpacity={0} />
      </radialGradient>
    </defs>
    <g
      className="icon-no-colormode"
      id="aa042f3b-22f0-4d3b-829e-613e5b3c42fa"
      data-name="color"
    >
      <circle
        className="icon-no-colormode"
        cx={12.14}
        cy={11.95}
        r={5.64}
        fill="url(#a83a17d9-82b9-4886-87d0-422768d6e7c3)"
      />
    </g>
    <g id="b89e5e5c-207a-4cea-b6a5-bee35198d10d" data-name="icon">
      <circle
        cx={12.02}
        cy={12}
        r={4.27}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={11.9}
        y1={2.47}
        x2={11.9}
        y2={5.53}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={18.68}
        y1={5.18}
        x2={16.51}
        y2={7.34}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={21.55}
        y1={11.88}
        x2={18.49}
        y2={11.88}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={18.84}
        y1={18.66}
        x2={16.68}
        y2={16.49}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={12.14}
        y1={21.53}
        x2={12.14}
        y2={18.47}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={5.36}
        y1={18.82}
        x2={7.53}
        y2={16.66}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={2.49}
        y1={12.12}
        x2={5.55}
        y2={12.12}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={5.2}
        y1={5.34}
        x2={7.36}
        y2={7.51}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbSunColor);
export default Memo;

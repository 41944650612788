import { ScColor, TAILWIND_COLORS, getTailColor } from "../util/color";

export const defaultLineColor = (scColor: ScColor) => {
  const color = TAILWIND_COLORS[getTailColor(scColor)];
  return {
    fill: true,
    pointRadius: 0,
    borderWidth: 1,
    borderColor: color,
    backgroundColor: (context: any) => {
      const ctx = context.chart.ctx;
      const area = context.chart.chartArea;
      if (!area) return null;

      const gradient = ctx.createLinearGradient(0, area.top, 0, area.bottom);
      gradient.addColorStop(0, color + "4C");
      gradient.addColorStop(1, color + "0C");
      return gradient;
    },
  };
};

import {
  AggregateResponse,
  HistoricalAggregateBody,
  HistoricalAggregateParams,
  HistoricalData,
  HistoricalDataParams,
  HistoricalDataResponse,
  LoginDetails,
  LogoutSuccess,
  User,
} from "./types";

export class ApiError extends Error {
  res: Response;

  constructor(res: Response) {
    super("API Error");
    this.res = res;
  }
}

export function parseParams(params: {}) {
  return Object.entries(params).reduce((acc, [key, val], i) => {
    const prefix = i === 0 ? "?" : "&";
    return acc + `${prefix}${key}=${val}`;
  }, "");
}

async function request<T>(path: string, init?: RequestInit) {
  const defaultInit: RequestInit = {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };

  const res = await fetch(`${window.settings.API_BASE_URL}${path}`, {
    ...defaultInit,
    ...init,
  });
  if (res.ok) return res.json() as T;
  throw new ApiError(res);
}

export async function login(body: LoginDetails) {
  const json = await request<User>(`user/login`, {
    body: JSON.stringify(body),
    method: "POST",
  });
  return json;
}

export async function logout() {
  const json = await request<LogoutSuccess>(`user/logout`, {
    method: "POST",
  });
  return json.success;
}

export async function getUser() {
  const json = await request<User>(`user`);
  return json;
}

export async function getHistoricalData(
  params: HistoricalDataParams
): Promise<HistoricalData[]> {
  const json: HistoricalDataResponse = await request(
    `historical/${parseParams(params)}`,
    {
      method: "GET",
      credentials: "omit",
    }
  );
  return json.data;
}

export async function aggregateHistoricalData(
  params: HistoricalAggregateParams,
  body: HistoricalAggregateBody
): Promise<AggregateResponse<keyof typeof body.items>> {
  const json: AggregateResponse<keyof typeof body.items> = await request(
    `aggregate${parseParams(params)}`,
    {
      method: "POST",
      credentials: "omit",
      body: JSON.stringify(body),
    }
  );
  return json;
}

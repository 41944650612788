import { GridItemSize, LayoutDefinition } from "../types/grid";

export const DRAGGABLE_HANDLE_CLASS = "grid-handle";
export const DRAGGABLE_CANCEL_CLASS = "grid-cancel";

export const GRID_BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1260,
  "2xl": 1687,
  "3xl": 1920,
  "4xl": 2400,
};
export const GRID_COLS = {
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3,
  "2xl": 3,
  "3xl": 4,
  "4xl": 5,
};
export const GRID_MARGIN = [30, 30] as const;
export const ROW_HEIGHT = 218;

export const GRID_ITEM_LAYOUTS: Record<GridItemSize, LayoutDefinition> = {
  [GridItemSize.MAIN]: {
    w: 1,
    h: 2,
    minH: 2,
    minW: 1,
    maxH: 2,
    maxW: 1,
  },
  [GridItemSize.SMALL]: {
    w: 1,
    h: 1,
    minH: 1,
    minW: 1,
    maxH: 1,
    maxW: 1,
  },
  [GridItemSize.BIG]: {
    w: 2,
    h: 2,
    minH: 2,
    minW: 2,
    maxH: 2,
    maxW: 2,
  },
  [GridItemSize.LONG]: {
    w: 1,
    h: 3,
    minH: 3,
    minW: 1,
    maxH: 3,
    maxW: 1,
  },
  [GridItemSize.WIDE]: {
    w: 2,
    h: 1,
    minH: 1,
    minW: 2,
    maxH: 1,
    maxW: 2,
  },
};

import React from "react";
import { SVGProps, memo } from "react";

const SvgScDbRainColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <radialGradient
        id="b5b48fbe-839d-4f36-bc63-8eb611ca2037"
        cx={12}
        cy={8.66}
        r={6.26}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#a0cbcc" stopOpacity={0.8} />
        <stop offset={0.16} stopColor="#a0cbcc" stopOpacity={0.78} />
        <stop offset={0.31} stopColor="#a0cbcc" stopOpacity={0.72} />
        <stop offset={0.47} stopColor="#a0cbcc" stopOpacity={0.62} />
        <stop offset={0.63} stopColor="#a0cbcc" stopOpacity={0.49} />
        <stop offset={0.78} stopColor="#a0cbcc" stopOpacity={0.31} />
        <stop offset={0.94} stopColor="#a0cbcc" stopOpacity={0.09} />
        <stop offset={1} stopColor="#a0cbcc" stopOpacity={0} />
      </radialGradient>
    </defs>
    <g id="abd51bd4-88da-4d56-bb44-dc17d2d112c0" data-name="color">
      <path
        d="M16.72,6.85a3.61,3.61,0,0,0-7.13-.1H8.05a3.42,3.42,0,1,0,0,6.83H16a3.41,3.41,0,0,0,.77-6.73Z"
        fill="url(#b5b48fbe-839d-4f36-bc63-8eb611ca2037)"
      />
    </g>
    <g id="aba2abcf-1a39-436a-85e4-d30f2e1a7c3d" data-name="icon">
      <path
        d="M16.72,7a3.61,3.61,0,0,0-7.13-.09H8.05a3.41,3.41,0,1,0,0,6.82H16A3.41,3.41,0,0,0,16.72,7Z"
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <line
        x1={7.65}
        y1={16}
        x2={5.32}
        y2={20.11}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={11.53}
        y1={15.98}
        x2={9.2}
        y2={20.09}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1={15.41}
        y1={16.02}
        x2={13.08}
        y2={20.12}
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const Memo = memo(SvgScDbRainColor);
export default Memo;

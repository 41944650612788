import { NamedFCWithChildren } from "../../../types/components";

export interface TableListItemProps {
  className?: string;
  noSpacerBefore?: boolean;
}

const TableListRowItem: NamedFCWithChildren<TableListItemProps> = ({
  children,
  className,
}) => {
  return <td className={`${className ?? ""}`}>{children}</td>;
};
TableListRowItem.displayName = "TableListRowItem";

export { TableListRowItem };

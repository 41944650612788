import React from "react";
import { SVGProps, memo } from "react";

const SvgScIconsBike = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="f8db75dd-e965-443d-9615-dd32097a4304"
    data-name="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle
      cx={6}
      cy={16}
      r={3.5}
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
    />
    <circle
      cx={18}
      cy={16}
      r={3.5}
      fill="none"
      stroke="#fff"
      strokeMiterlimit={10}
    />
    <polygon
      points="15.5 10.5 12.5 15.81 18.5 15.81 15.5 10.5"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polygon
      points="7.87 10.5 12.49 15.81 15.5 10.5 7.87 10.5"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polyline
      points="5.89 15.81 8.89 7.5 10.89 7.5"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polyline
      points="15.5 10.5 16.5 8.5 14.5 8.5 17.5 8.5"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgScIconsBike);
export default Memo;

import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { defaultLineColor } from "../../../chartjs/util";
import { GridItemSize } from "../../grid/item";
import { LegendItem } from "../../util/legend-item";
import { StateBoard } from "../base";
import { StateboardComponent } from "../../../types/stateboard";
import { useStateboard } from "../../../hooks/useStateboard";
import { getTimeFrameBySorting } from "../../../api/utils";
import { GRAPH_COLOR_ORDER } from "../../../constants/stateboards";
import { HistoricalData } from "../../../api/types";
import { useHistoricalDataQuery } from "../../../api/hooks";
import { throwOnNoData } from "../../../util/throw-no-data";

export const Content = ({ data }: { data: HistoricalData[] }) => {
  throwOnNoData(data);

  const pegelstaende = useMemo(() => {
    return data;
  }, [data]);

  const chartConfig = React.useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
        },
        y: {
          type: "linear",
        },
      },
    };
  }, []);

  const chartData = React.useMemo(() => {
    const data = {
      datasets: pegelstaende.map((item, i) => {
        const p = item.name;

        const values = item.singleDataPoints;
        return {
          label: p,
          data: values.map((dat) => {
            return {
              x: new Date(dat.datetime),
              y: Number(dat.value),
            };
          }),
          ...defaultLineColor(GRAPH_COLOR_ORDER[i]),
        };
      }),
    };

    return data;
  }, [pegelstaende]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row  mb-3">
        {pegelstaende.map((item, i) => {
          const p = item!.name!;
          const values = item!.singleDataPoints;
          return (
            <LegendItem
              unit="cm"
              name={p}
              color={GRAPH_COLOR_ORDER[i]}
              key={"waterlevel_" + p}
              value={values[0]!.value}
            ></LegendItem>
          );
        })}
      </div>
      <div>
        <Line height="93%" options={chartConfig as any} data={chartData}></Line>
      </div>
    </div>
  );
};
const REFETCH_INTERVAL = 1000 * 60 * 60;

export const WaterLevelStateboard: StateboardComponent = ({ id }) => {
  const { t } = useTranslation();
  const { sorting, setSorting, timeFrame } = useStateboard(REFETCH_INTERVAL);

  const { data, error, isLoading } = useHistoricalDataQuery({
    valueKey: "level",
    datetime__gte: timeFrame.start.toISOString(),
    groupIdentifier: "80a8b019-d29a-45f9-9546-d4bff59c3bee",
  });
  return (
    <StateBoard
      size={GridItemSize.SMALL}
      loading={isLoading && !data}
      error={!!error}
      sorting={sorting}
      onSortingChange={setSorting}
    >
      <StateBoard.Title title={t("WATER_LEVEL_STATEBOARD_TITLE")} />
      <StateBoard.Content>{data && <Content data={data} />}</StateBoard.Content>
      {/* <StateBoard.Select /> */}
    </StateBoard>
  );
};

import { GridItemSize, GRID_ITEM_LAYOUTS } from "../components/grid/item";
import { BikeBoxStateboard } from "../components/stateboards/bikebox";
import { EnvironmentStateboard } from "../components/stateboards/environment";
import { ParkingMapStateboard } from "../components/stateboards/parking-map";
import { ParkingSummaryStateboard } from "../components/stateboards/parking-summary";
import { PersonFrequncyStateboard } from "../components/stateboards/person-frequency";
import { PersonFrequncyDetailStateboard } from "../components/stateboards/person-frequency-detail";
import { SoilMoistureStateboard } from "../components/stateboards/soil-moisture";
import { TrafficStateboard } from "../components/stateboards/traffic";
import { TrashCanStateboard } from "../components/stateboards/trashcans";
import { VisitorChartStateboard } from "../components/stateboards/visitor-chart";
import { WaterLevelStateboard } from "../components/stateboards/water-level";
import { WeatherStateboard } from "../components/stateboards/weather";
import { Stateboard, StateboardType } from "../types/stateboard";
import { ScColor } from "../util/color";

// All registered state boards
export const STATE_BOARDS: Record<string, Stateboard> = {
  // [StateboardType.SOIL_MOISTURE]: {
  //   Component: SoilMoistureStateboard,
  //   layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.MAIN],
  // },
  [StateboardType.TRASHCAN]: {
    Component: TrashCanStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.SMALL],
  },
  [StateboardType.ENVIRONMENT]: {
    Component: EnvironmentStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.SMALL],
  },
  [StateboardType.TRAFFIC]: {
    Component: TrafficStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.LONG],
  },
  [StateboardType.WATER_LEVEL]: {
    Component: WaterLevelStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.SMALL],
  },
  [StateboardType.WEATHER]: {
    Component: WeatherStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.SMALL],
  },
  [StateboardType.BIKEBOX]: {
    Component: BikeBoxStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.SMALL],
  },
  [StateboardType.PARKING_SUMMARY]: {
    Component: ParkingMapStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.MAIN],
  },
  // [StateboardType.VISITORCHART]: {
  //   Component: VisitorChartStateboard,
  //   layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.MAIN],
  // },
  [StateboardType.PERSON_FREQUENCY]: {
    Component: PersonFrequncyStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.LONG],
  },
  [StateboardType.PERSON_FREQUENCY_DETAIL]: {
    Component: PersonFrequncyDetailStateboard,
    layoutDefinition: GRID_ITEM_LAYOUTS[GridItemSize.MAIN],
  },
};
export const GRAPH_COLOR_ORDER: ScColor[] = [
  "pink",
  "turquoise",
  "purple",
  "orange",
  "blue",
  "green",
];

import Lottie from "lottie-react";
import React from "react";
import loadingAnim from "../../../../assets/lottie/sc_loading_state_v2_slower.json";
export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: React.ReactNode;
  loading?: boolean;
}
const LoadingIcon = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-7 ">
        <Lottie
          loop={true}
          autoplay={true}
          animationData={loadingAnim}
          rendererSettings={{
            className: "icon-no-colormode",
          }}
        ></Lottie>
      </div>
    </div>
  );
};
export const Button = ({
  children,
  loading,
  className: _,
  ...rest
}: ButtonProps) => {
  const hover = "dark:hover:bg-active-dark hover:bg-active-light";
  const active = "dark:active:bg-focus-dark active:bg-focus-light";
  const pointerEvents = `${loading ? "pointer-events-none" : ""}`;
  return (
    <button
      {...rest}
      className={`p-2.5 font-normal relative text-lg transition-colors dark:bg-inactive-dark bg-inactive-light ${hover} ${active} ${pointerEvents} dark:shadow-md-dark sahdow-md-light rounded-sm`}
    >
      {!!loading ? <LoadingIcon /> : children}
    </button>
  );
};
